import { Divider } from '@mui/material';
import React from 'react';

import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import { paramNames } from '@/constants';
import panelTypes from '@/constants/panelTypes';
import tooltips from '@/utils/tooltips';

import { useDashboardActionsContext } from '../DashboardActionsProvider';
import FilterTagsContainer from '../FilterTags/FilterTagsContainer';
import getFilterParamNames from '../helpers/getFilterParamNames';
import styles from './DashboardPanel.module.scss';
import getIsInsightPanel from './helpers/getIsInsightPanel';
import PanelParamCategory from './PanelParamCategory';
import PanelParamLocation from './PanelParamLocation';
import PanelParamsModifiers from './PanelParamsModifiers';

const PanelParams = ({
  panelType, panelId, panelParams,
}) => {
  const dashboardActions = useDashboardActionsContext();
  const isInsightPanel = getIsInsightPanel(panelType);
  const isHeatmap = panelType === panelTypes.heatmap;
  const isFreeformText = panelType === panelTypes.freeformText;

  const handleParamChange = ({ key, value }) => {
    dashboardActions.changePanelParams({ panelId, nextParams: { [key]: value } });
  };

  const handleParamsChange = (nextParams) => {
    dashboardActions.changePanelParams({ panelId, nextParams });
  };

  const handleParamDelete = (key) => {
    dashboardActions.deletePanelParam({ panelId, key });
  };

  const handleReset = () => {
    const filterType = panelParams?.[paramNames.filterType];
    const filterParamNames = getFilterParamNames(filterType);
    dashboardActions.deletePanelParams({ panelId, paramNames: filterParamNames });
  };

  return (
    <div className={styles.panelParams}>
      <div className={styles.panelParamsFields}>
        {!isFreeformText && (
          <PanelParamsModifiers
            panelId={panelId}
            panelParams={panelParams}
          />
        ) }
        <Divider />
        {isInsightPanel ? (
          <div className={styles.panelParamAndFiltersWrapper}>
            <div className={styles.panelParamCategoryFilters}>
              {isHeatmap ? (
                <PanelParamLocation
                  location={panelParams?.[paramNames.filterLocation]}
                  onChange={(nextLocation) => handleParamChange({ key: paramNames.filterLocation, value: nextLocation })}
                  onReset={() => handleParamDelete(paramNames.filterLocation)}
                />
              ) : (
                <PanelParamCategory
                  panelParams={panelParams}
                  onParamsChange={handleParamsChange}
                  enforceInlineBehavior
                />
              )}
            </div>

            <div className={styles.panelParamsFilters}>
              {!isHeatmap ? (
                <TooltipInfo title={tooltips.dashboard.filter}>
                  <div>
                    <FilterTagsContainer
                      params={panelParams}
                      onParamsChange={handleParamChange}
                      onParamDelete={handleParamDelete}
                      onReset={handleReset}
                    />
                  </div>
                </TooltipInfo>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PanelParams;
