import qs from 'qs';
import Session from 'supertokens-auth-react/recipe/session';

import { api, paramNames } from '@/constants';

import { serializeInsightsParamsObject } from './serializeInsightsParams';

// TODO: use async/await
export const fetchJson = (url, options = {}) => {
  return fetch(url, options).then(
    async (response) => {
      if (response.ok) {
        const text = await response.text();
        try {
          return JSON.parse(text);
        } catch (e) {
          return Promise.reject(text);
        }
      }

      const text = await response.text();
      return Promise.reject(new Error(`Error fetching url: ${url}: status code: ${response.status}: error: ${text}`));
    },
    (error) => {
      Promise.reject(error);
    },
  );
};

export const fetchInsightsJson = (params) => {
  const url = `${api.hostname}/api/${api.server}/v2/insights?${qs.stringify(params)}`;
  return fetchJson(url);
};

export const fetchInsightsTags = ({
  baseParams, category, params, tagType,
}) => {
  const tempParams = {
    [paramNames.filterType]: 'urn:tag',
    ...(tagType ? { [paramNames.filterTagTypes]: `urn:tag:${tagType}:qloo` } : {}),
    ...(category ? { [paramNames.filterParentTypes]: `urn:entity:${category}` } : {}),

  };

  const insightsParams = serializeInsightsParamsObject(baseParams, tempParams);
  return fetchInsightsJson({ ...insightsParams, ...(params || {}) });
};

export const fetchInsightsEntities = ({ baseParams, category, params }) => {
  const tempParams = {
    [paramNames.filterType]: `urn:entity:${category}`,
  };
  const insightsParams = serializeInsightsParamsObject(baseParams, tempParams);
  return fetchInsightsJson({ ...insightsParams, ...(params || {}) });
};

export const fetchGpt = ({ prompt }) => {
  return Session.getAccessToken()
    .then((accessToken) => {
      const headers = new Headers();
      headers.append('Content-type', 'application/json');
      headers.append('Authorization', `Bearer ${accessToken}`);
      return fetchJson(
        'https://q3x5x2n5akw73pub4kp5bgdpzy0nujea.lambda-url.us-east-1.on.aws/',
        { method: 'POST', headers, body: JSON.stringify({ prompt }) },
      );
    })
    .then((result) => result?.results?.choices?.[0]?.message.content?.trim?.());
};
