import PaletteIcon from '@mui/icons-material/Palette';
import Github from '@uiw/react-color-github';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { NestedMenuItem } from 'mui-nested-menu';

import { useActiveDashboard } from '@/hooks';

import { useDashboardActionsContext } from '../../DashboardActionsProvider';
import styles from './MenuItemColor.module.scss';

const borderColors = [
  '#FF0033',
  '#FF6600',
  '#FFCC00',
  '#00CC00',
  '#6600CC',
  '#CC00CC',
  '#0167FF',
  '#F7ADB1',
  '#F8C6AB',
  '#FCECB5',
  '#BEEAB1',
  '#BE9FE8',
  '#E4A9E8',
  '#AAC1FB',
];

const MenuItemColor = ({ panelId, parentMenuState }) => {
  const dashboard = useActiveDashboard();
  const dashboardActions = useDashboardActionsContext();

  const colorMenuState = usePopupState({
    variant: 'popper',
    popupId: 'colorMenuState',
  });

  const { color } = dashboard.panels[panelId].settings;

  const handleChange = (nextColor) => {
    const nextSettings = {
      color: color === nextColor.hex ? null : nextColor.hex,
    };
    dashboardActions.changePanelSettings({ panelId, nextSettings });
  };

  return (
    <NestedMenuItem
      label="Highlight Panel"
      leftIcon={<PaletteIcon sx={{ fill: color }} />}
      parentMenuOpen={parentMenuState.isOpen}
      className={styles.nestedMenuItem}
      {...bindTrigger(colorMenuState)}
    >
      <Github
        placement={false}
        color={color}
        className={styles.colorPicker}
        onChange={handleChange}
        colors={borderColors}
      />
    </NestedMenuItem>
  );
};

export default MenuItemColor;
