import React from 'react';

import tagTypesBySelectedCategory from '@/constants/tagTypesBySelectedCategory';

import SelectedCategoryContentTagsSingle from './SelectedCategoryContentTagsSingle';
import SelectedCategoryContentTagsTabs from './SelectedCategoryContentTagsTabs';

const SelectedCategoryContentTags = ({
  onSpecificDataSelect,
  panel,
  selectedCategory,
  selectedData,
  highlightedSelectedCategory,
}) => {
  const tagTypes = tagTypesBySelectedCategory[selectedCategory] || [];
  if (tagTypes.length) {
    return (
      <SelectedCategoryContentTagsTabs
        panel={panel}
        selectedCategory={selectedCategory}
        tagTypes={tagTypes}
        highlightedSelectedCategory={highlightedSelectedCategory}
      />
    );
  }

  return (
    <SelectedCategoryContentTagsSingle
      onSpecificDataSelect={onSpecificDataSelect}
      panel={panel}
      selectedCategory={selectedCategory}
      selectedData={selectedData}
      highlightedSelectedCategory={highlightedSelectedCategory}
    />
  );
};

export default SelectedCategoryContentTags;
