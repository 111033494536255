import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const getHasPermission = ({ identity, requiredPermission }) => {
  if (!identity) return false;

  const permissionTags = identity.calculatedPermissionTagIds || [];
  return permissionTags.includes(requiredPermission) || permissionTags.includes('role.superuser');
};

const useHasPermission = (requiredPermission) => {
  const { identity } = useSelector((state) => state.app);

  const hasPermission = useMemo(() => getHasPermission({ identity, requiredPermission }), [identity, requiredPermission]);

  return hasPermission;
};

export default useHasPermission;
