import { getReasonPhrase } from 'http-status-codes';

const extractErrorMessages = (error) => {
  if (!error) return 'Unknown error occurred';

  if (error?.message) {
    if (typeof error.message === 'string') {
      return error.message;
    }

    return JSON.stringify(error.message);
  }

  if (error?.status) {
    return `Error ${error.status} - ${getReasonPhrase(error.status)}`;
  }

  if (Array.isArray(error)) {
    return error.map(extractErrorMessages).join(', ');
  }

  try {
    if (error?.toString) {
      return error.toString();
    }
  } catch (err) {
    return 'Unknown error occurred';
  }

  return 'Unknown error occurred';
};

export default extractErrorMessages;
