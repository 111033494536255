// Others
import './index.scss';

import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@/components/Button';
import ErrorBoundary from '@/components/ErrorBoundary';
import useIsScreenshotting from '@/hooks/useIsScreenshotting';
import { useCreateDashboardMutation, useGetDashboardsQuery } from '@/store/apis/dashboardsApi';
import getDashboardCountAndFirstId from '@/utils/getDashboardCountAndFirstId';

import DashboardPageErrorFallback from './DashboardPageErrorFallback';
import DashboardTabsWrapper from './DashboardTabs/DashboardTabsWrapper';
import DashboardView from './DashboardView/DashboardView';

const DashboardPage = () => {
  const navigate = useNavigate();
  const { isLoading } = useGetDashboardsQuery();
  const [createDashboard, { isLoading: isLoadingCreateDashboard }] = useCreateDashboardMutation();
  const { id } = useParams();
  const { items } = useSelector((state) => state.dashboards);
  const { dashboardIds } = useSelector((state) => state.dashboardTabs);

  const isScreenshotting = useIsScreenshotting();

  useEffect(() => {
    const { firstId } = getDashboardCountAndFirstId(dashboardIds, items);
    if (!id && dashboardIds.length >= 1) {
      navigate(`/dashboards/view/${firstId}`);
    }
  }, [id, dashboardIds, items, navigate]);

  const handleCreate = async () => {
    const { data } = await createDashboard();
    navigate(`/dashboards/view/${data.id}`);
  };

  return (
    <ErrorBoundary FallbackComponent={DashboardPageErrorFallback}>
      {!isScreenshotting && <DashboardTabsWrapper isLoading={isLoading} />}
      {id && <DashboardView isLoading={isLoading} key={id} />}
      {(!isLoading && !id) && (
        <Box display="flex" alignItems="center" justifyContent="center" height="100px">
          <Button
            isLoading={isLoadingCreateDashboard}
            onClick={handleCreate}
          >
            Add New Dashboard
          </Button>
        </Box>
      )}
    </ErrorBoundary>
  );
};

export default DashboardPage;
