import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import { useUserDashboardIdentity } from '@/hooks';
import { useUpdateIdentityDashboardIsFavoriteMutation } from '@/store/apis/dashboardsApi';
import tooltips from '@/utils/tooltips';

const MenuItemFavorite = ({
  dashboard, hideLabel, hasHorizontalPadding, ...props
}) => {
  const userDashboardIdentity = useUserDashboardIdentity(dashboard.id);
  const [updateIdentityDashboardIsFavorite] = useUpdateIdentityDashboardIsFavoriteMutation();

  const isFavorite = !!userDashboardIdentity?.isFavorite;

  const leftPadding = hasHorizontalPadding ? '16px' : 0;
  const rightPadding = hasHorizontalPadding ? '16px' : 0;

  const handleClick = (event) => {
    event.stopPropagation();
    const { dashboardId } = dashboard;
    const { identityId } = userDashboardIdentity;
    updateIdentityDashboardIsFavorite({ dashboardId, identityId, isFavorite: !isFavorite });
  };

  const propsWithoutPadding = { ...props };
  delete propsWithoutPadding.noPadding;

  return (
    <TooltipInfo title={tooltips.dashboardCard.favorite} followCursor>
      <MenuItem onClick={handleClick} {...propsWithoutPadding} sx={{ paddingLeft: leftPadding, paddingRight: rightPadding }}>
        <ListItemIcon>
          {isFavorite ? <StarIcon htmlColor="yellow" /> : <StarBorderIcon />}
        </ListItemIcon>
        {!hideLabel && <ListItemText>Favorite</ListItemText>}
      </MenuItem>
    </TooltipInfo>
  );
};

export default MenuItemFavorite;
