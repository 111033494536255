import LinearProgress from '@mui/material/LinearProgress';
import flatten from 'lodash.flatten';
import React, { useEffect, useMemo } from 'react';

import tagTypesBySelectedCategory from '@/constants/tagTypesBySelectedCategory';
import { useRequest } from '@/hooks';
import { fetchGpt, fetchInsightsEntities, fetchInsightsTags } from '@/utils/fetch';

import styles from './CreativesModal.module.scss';
import CreativesModalOutput from './CreativesModalOutput';
import CreativesModalResult from './CreativesModalResult';
import useOutput from './useOutput';

const mapEntitiesByName = (item) => item.name;

const fetchEntities = ({ baseParams, entitiesRequests, wrappedFetchInsightsEntities }) => {
  return Promise.all(
    entitiesRequests.map((entitiesRequest) => wrappedFetchInsightsEntities({ baseParams, ...entitiesRequest })
      .then((result) => result?.results?.entities || [])),
  ).then(flatten);
};

const getFlattenedTagsRequests = (tagsRequests) => tagsRequests.reduce((arr, tagsRequest) => {
  if (tagsRequest.fetchByTagTypes) {
    const { category } = tagsRequest;
    const categoryUrn = `urn:entity:${category}`;
    const tagTypeUrns = tagTypesBySelectedCategory[categoryUrn] || [];
    const tagTypes = tagTypeUrns.map((tagTypeUrn) => {
      const parts = tagTypeUrn.split(':');
      return parts[2];
    });

    const tagTypetagRequests = tagTypes.map((tagType) => ({ ...tagsRequest, tagType }));
    return [...arr, ...tagTypetagRequests];
  }
  return [...arr, tagsRequest];
}, []);

const fetchTags = ({ baseParams, tagsRequests, wrappedFetchInsightsTags }) => {
  const flattenedTagsRequests = getFlattenedTagsRequests(tagsRequests);

  return Promise.all(
    flattenedTagsRequests.map((tagsRequest) => wrappedFetchInsightsTags({ baseParams, ...tagsRequest })
      .then((result) => result?.results?.tags || []).then(
        (tags) => tags.map((tag) => {
          return {
            ...tag,
            id: tag.tag_id,
            tagId: tag.tag_id,
            category: tagsRequest.category,
            tagType: tagsRequest.tagType,
          };
        }),
      )),
  ).then(flatten);
};

const CreativesModalStepTwo = ({ baseParams, template, userData }) => {
  const output = useOutput();
  const { wrapFn } = output;
  const wrappedFetchInsightsEntities = wrapFn(fetchInsightsEntities, 'Fetching insights entities');
  const wrappedFetchInsightsTags = wrapFn(fetchInsightsTags, 'Fetching insights tags');
  const wrappedFetchGpt = wrapFn(fetchGpt, 'Fetching llm request');

  const entitiesRequest = useRequest(fetchEntities);
  const tagsRequest = useRequest(fetchTags);

  const insights = useMemo(
    () => (typeof template.insights === 'function' ? template.insights({ userData }) : template.insights),
    [template, userData],
  );

  const runnerRequest = useRequest(async () => {
    const [entities, tags] = await Promise.all([
      entitiesRequest.call({ baseParams, entitiesRequests: insights?.entities || [], wrappedFetchInsightsEntities }),
      tagsRequest.call({ baseParams, tagsRequests: insights?.tags || [], wrappedFetchInsightsTags }),
    ]);

    const insightsNames = [...(entities || []), ...(tags || [])].map(mapEntitiesByName);

    const result = await template.runner({
      baseParams,
      entities,
      fetchGpt: wrappedFetchGpt,
      insightsNames,
      tags,
      userData,
      wrapFn,
    });

    return result;
  });

  useEffect(() => {
    runnerRequest.call();
  }, []);

  return (
    <>
      <div className={styles.contentContainer}>
        <div className={styles.result}>
          <CreativesModalResult
            baseParams={baseParams}
            entitiesRequest={entitiesRequest}
            runnerRequest={runnerRequest}
            tagsRequest={tagsRequest}
            template={template}
          />
          {runnerRequest.error ? (
            <div>{runnerRequest.error?.toString ? runnerRequest.error.toString() : JSON.stringify(runnerRequest.error)}</div>
          ) : null}
        </div>
      </div>
      {runnerRequest.isLoading ? <LinearProgress /> : null}
      <CreativesModalOutput output={output} />
    </>
  );
};

export default CreativesModalStepTwo;
