import { createTheme } from '@mui/material/styles';

import mainTheme from './mainTheme';

const darkTheme = createTheme(mainTheme, {
  ...mainTheme,
  palette: {
    mode: 'dark',
    background: {
      default: '#000B14',
      paper: '#000B14',
    },
    text: {
      primary: '#F8FAFC',
    },
    primary: {
      main: '#90CAF9',
      light: '#E3F2FD',
      dark: '#42A5F5',
      contrastText: '#000000',
    },
    secondary: {
      main: '#787F91',
      contrastText: '#F8FAFC',
    },
    accent: {
      main: '#131D25',
      contrastText: '#F8FAFC',
    },
    muted: {
      main: '#1E293B',
      contrastText: '#94A3B8',
    },
    destructive: {
      main: '#7F1D1D',
      contrastText: '#F8FAFC',
    },
    success: {
      main: '#388E3C',
      contrastText: '#F8FAFC',
    },
    danger: {
      main: '#D32F2F',
      contrastText: '#F8FAFC',
    },
    orange: {
      main: '#49211D',
      light: '#7B4E4A',
      dark: '#2C1311',
      contrastText: '#F25731',
    },
    purple: {
      main: '#221E33',
      light: '#3A364D',
      dark: '#161327',
      contrastText: '#724C7B',
    },
    blue: {
      main: '#063441',
      light: '#356471',
      dark: '#021D26',
      contrastText: '#1396AA',
    },
    gray: {
      main: '',
      contrastText: '',
    },
    yellow: {
      main: '#D1B28C',
      contrastText: '#4C3B2D',
    },
    yellowInverted: {
      main: '#4C3B2D',
      contrastText: '#D1B28C',
    },
    lightBlue: {
      main: '#112333',
      light: '#1b3b52',
      dark: '#0b1a23',
      contrastText: '#bbd4ff',
    },
    darkBlue: {
      main: '#0c1d28',
      light: '#16364d',
      dark: '#08121b',
      contrastText: '#a0c9ff',
    },
  },
});

export default darkTheme;
