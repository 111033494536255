import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { filterTypes, paramNames } from '@/constants';
import panelTypes from '@/constants/panelTypes';
import {
  useDeleteDashboardPanelMutation,
  useUpdateDashboardBaseQueryMutation,
  useUpdateDashboardLayoutMutation,
  useUpdateDashboardPanelsMutation,
} from '@/store/apis/dashboardsApi';
import { updateDashboard } from '@/store/slices/dashboardsSlice';

import { getNextLayoutItem } from '../helpers';
import { useLiveState } from '../hooks';

const useDashboardActions = ({ dashboard }) => {
  const dispatch = useDispatch();
  const {
    baseParams, dashboardId, id, layout, panels,
  } = dashboard;

  const [updateDashboardBaseParams, { isLoading: isUpdatingBaseQuery }] = useUpdateDashboardBaseQueryMutation();
  const [updateDashboardLayout, { isLoading: isUpdatingLayout }] = useUpdateDashboardLayoutMutation();
  const [updateDashboardPanels, { isLoading: isUpdatingPanels }] = useUpdateDashboardPanelsMutation();
  const [deleteDashboardPanel] = useDeleteDashboardPanelMutation();

  const { emit } = useLiveState({
    liveStateDataId: dashboardId,
    onChange: (update) => {
      dispatch(updateDashboard(update));
    },
  });

  const addPanel = async ({
    params = {}, settings = {}, partialLayout = {}, panelType = panelTypes.table,
  } = {}) => {
    const oldPanels = { ...panels };
    const oldLayout = [...layout];
    const panelId = uuidv4();

    const nextLayoutItem = {
      ...getNextLayoutItem(panelId, layout),
      ...partialLayout,
    };
    const nextLayout = [...oldLayout, nextLayoutItem];

    const panel = {
      panelId,
      panelType,
      params,
      settings,
    };

    const tempPanels = { ...oldPanels, [panelId]: { ...panel, isNew: true } };

    const tempPayload = {
      dashboardId,
      id,
      layout: nextLayout,
      panels: tempPanels,
    };

    // Local State
    dispatch(updateDashboard(tempPayload));

    try {
      const { data: newPanel } = await updateDashboardPanels({ dashboardId, panel });

      // Temporal patch for new panel type (Remove after backend implementation)
      const tempPanel = { ...newPanel, panelType };

      const nextPanels = { ...oldPanels, [panelId]: tempPanel };

      const payload = {
        dashboardId,
        id,
        layout: nextLayout,
        panels: nextPanels,
      };

      // Local State
      dispatch(updateDashboard(payload));

      // Live State
      emit(payload);

      // Server State
      updateDashboardLayout(payload);
    } catch (error) {
      // Undo local state changes
      dispatch(updateDashboard({
        dashboardId, id, layout: oldLayout, panels: oldPanels,
      }));
    }
  };

  const addHeatmapPanel = async (params = {}) => {
    return addPanel({
      panelType: panelTypes.heatmap,
      params: {
        [paramNames.filterType]: filterTypes.heatmap,
        [paramNames.filterLocation]: baseParams[paramNames.signalLocation],
        ...params,
      },
    });
  };

  const addExplorePanel = async (params = {}, settings = {}) => {
    return addPanel({
      panelType: panelTypes.explore,
      params,
      settings,
      partialLayout: {
        w: 12,
        h: 12,
      },
    });
  };

  const addLocationExplorerPanel = async (params = {}) => {
    return addPanel({
      panelType: panelTypes.locationExplorer,
      params,
      partialLayout: {
        w: 12,
        h: 12,
      },
    });
  };

  const addFreeformTextPanel = async (params = {}) => {
    return addPanel({
      panelType: panelTypes.freeformText,
      params,
      partialLayout: {
        w: 3,
        h: 4,
      },
    });
  };

  const clonePanel = async (panelId) => {
    const originalPanel = panels[panelId];

    if (!originalPanel) {
      // TODO: add Sentry error
      console.error('Panel not found');
      return;
    }

    const { panelType, params, settings } = originalPanel;
    const originalLayoutItem = layout.find(({ i }) => i === panelId);

    // Clone only size
    const nextLayout = originalLayoutItem ? {
      w: originalLayoutItem.w,
      h: originalLayoutItem.h,
    } : {};

    addPanel({
      panelType, params, settings, partialLayout: nextLayout,
    });
  };

  const updateBaseParams = (nextBaseParams) => {
    const payload = { dashboardId, id, baseParams: nextBaseParams };
    updateDashboardBaseParams(payload);
    dispatch(updateDashboard(payload));
    emit(payload);
  };

  const changeBaseParam = ({ key, value }) => {
    const nextBaseParams = {
      ...baseParams,
      [key]: value,
    };

    updateBaseParams(nextBaseParams);
  };

  const changePanelTitle = async ({ panelId, nextTitle }) => {
    const nextPanel = {
      ...(panels[panelId] || {}),
      title: nextTitle,
    };

    const nextPanels = { ...panels, [panelId]: nextPanel };

    const payload = { dashboardId, id, panels: nextPanels };

    dispatch(updateDashboard(payload));

    try {
      await updateDashboardPanels({ dashboardId, panel: nextPanel }).unwrap();
      emit(payload);
    } catch (error) {
      dispatch(updateDashboard({ dashboardId, id, panels }));
      Sentry.captureException(error);
    }
  };

  const changePanelParams = async ({ panelId, nextParams }) => {
    const oldParams = panels[panelId]?.params || {};

    const nextPanel = {
      ...(panels[panelId] || {}),
      params: {
        ...oldParams,
        ...nextParams,
      },
    };

    // Reset offset when changing params
    if (oldParams[paramNames.offset] && !nextParams[paramNames.offset]) {
      delete nextPanel.params[paramNames.offset];
    }

    // Reset entities when category change
    if (!!nextParams[paramNames.filterType] && oldParams[paramNames.filterType] !== nextParams[paramNames.filterType]) {
      delete nextPanel.params[paramNames.filterEntities];
    }

    const nextPanels = { ...panels, [panelId]: nextPanel };

    const payload = { dashboardId, id, panels: nextPanels };

    dispatch(updateDashboard(payload));

    try {
      await updateDashboardPanels({ dashboardId, panel: nextPanel }).unwrap();
      emit(payload);
    } catch (error) {
      dispatch(updateDashboard({
        dashboardId, id, panels,
      }));
      Sentry.captureException(error);
    }
  };

  const deletePanelParam = ({ panelId, key }) => {
    const { [key]: _, ...nextParams } = panels[panelId]?.params || {};

    const nextPanel = {
      ...(panels[panelId] || {}),
      params: nextParams,
    };

    const nextPanels = { ...panels, [panelId]: nextPanel };

    const payload = {
      dashboardId, id, panelId, panels: nextPanels,
    };

    updateDashboardPanels({ dashboardId, panel: nextPanel });
    dispatch(updateDashboard(payload));
    emit(payload);
  };

  const deletePanelParams = ({ panelId, paramNames: deletePanelParamNames }) => {
    const nextParams = { ...(panels[panelId]?.params || {}) };

    deletePanelParamNames.forEach((paramName) => {
      nextParams[paramName] = null;
    });

    const nextPanel = {
      ...(panels[panelId] || {}),
      params: nextParams,
    };

    const nextPanels = { ...panels, [panelId]: nextPanel };

    const payload = {
      dashboardId, id, panelId, panels: nextPanels,
    };

    updateDashboardPanels({ dashboardId, panel: nextPanel });
    dispatch(updateDashboard(payload));
    emit(payload);
  };

  const changePanelSettings = async ({ panelId, nextSettings }) => {
    const nextPanel = {
      ...(panels[panelId] || {}),
      settings: nextSettings,
    };

    const nextPanels = { ...panels, [panelId]: nextPanel };

    const payload = {
      dashboardId, id, panelId, panels: nextPanels,
    };

    dispatch(updateDashboard(payload));

    try {
      await updateDashboardPanels({ dashboardId, panel: nextPanel }).unwrap();
      emit(payload);
    } catch (error) {
      dispatch(updateDashboard({
        dashboardId, id, panels,
      }));
      Sentry.captureException(error);
    }
  };

  const updateLayout = (nextLayout) => {
    const updatedLayout = nextLayout.map((item) => ({
      ...item,
      minH: undefined,
    }));

    const payload = { dashboardId, id, layout: updatedLayout };
    updateDashboardLayout(payload);
    dispatch(updateDashboard(payload));
    emit(payload);
  };

  const deletePanel = (panelId) => {
    const { [panelId]: _, ...nextPanels } = panels;
    const nextLayout = layout.filter(({ i }) => i !== panelId);

    const payload = {
      dashboardId, id, layout: nextLayout, panels: nextPanels,
    };

    // Local State
    dispatch(updateDashboard(payload));

    // Live State
    emit(payload);

    // Server State
    deleteDashboardPanel({ dashboardId, panelId, layout: nextLayout });
  };

  return {
    addPanel,
    addHeatmapPanel,
    addExplorePanel,
    addLocationExplorerPanel,
    addFreeformTextPanel,
    clonePanel,
    changeBaseParam,
    changePanelTitle,
    changePanelParams,
    deletePanelParam,
    deletePanelParams,
    changePanelSettings,
    deletePanel,
    updateBaseParams,
    updateLayout,
    isUpdatingBaseQuery,
    isUpdatingLayout,
    isUpdatingPanels,
  };
};

export default useDashboardActions;
