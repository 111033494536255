import brainstorm from './brainstorm';
import custom from './custom';
import personas from './personas';
import realEstate from './realEstate';
import spokesperson from './spokesperson/Spokesperson';

export default {
  // realEstateActionableInsights,
  realestate: realEstate, // no camelcasing for permission tags
  brainstorm,
  custom,
  spokesperson,
  personas,
};
