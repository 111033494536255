import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const useActiveDashboard = () => {
  const { id } = useParams();
  const memoedId = useMemo(() => {
    return id;
  }, [id]);

  const dashboard = useSelector((state) => state.dashboards.items[memoedId]);

  return dashboard;
};

export default useActiveDashboard;
