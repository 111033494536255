/* eslint-disable simple-import-sort/imports */

import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import {
  horizontalListSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Draggable from '@/components/Draggable';
import { useToggle } from '@/hooks';
import DashboardTabsSkeleton from '@/screens/Dashboard/DashboardTabs/DashboardTabsSkeleton';
import { useUpdateDashboardPositionMutation } from '@/store/apis/dashboardsApi';

import updateDashboardPositionsAfterDrag from '../helpers/updateDashboardPositionsAfterDrag';
import DashboardTab from './DashboardTab';
import styles from './DashboardTabs.module.scss';
import DashboardTabsAddButton from './DashboardTabsAddButton';
import DashboardTabsHomeButton from './DashboardTabsHomeButton';

const DashboardTabsWrapper = ({ isLoading }) => {
  const isReadyToggle = useToggle();
  useEffect(() => {
    isReadyToggle.on();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <DashboardTabsSkeleton />;
  }

  if (isReadyToggle.value) {
    return <DashboardTabs />;
  }

  return null;
};

const DashboardTabs = () => {
  const [updateDashboardPosition] = useUpdateDashboardPositionMutation();
  const { identity } = useSelector((state) => state.app);
  const { identityId } = identity;
  const params = useParams();
  const id = Number(params.id);
  const { items } = useSelector((state) => state.dashboards);
  const { dashboardIds } = useSelector((state) => state.dashboardTabs);

  const dashboards = useMemo(() => {
    if (!dashboardIds || !items) return [];

    return dashboardIds
      .map((dashboardId) => {
        const dashboard = items[dashboardId];
        if (!dashboard) return undefined;

        return {
          dashboard,
          dashboardId,
          tabPosition: dashboard.tabPosition,
        };
      })
      .filter((tab) => tab && tab.dashboard)
      .sort((a, b) => a.tabPosition - b.tabPosition);
  }, [items, dashboardIds]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor),
  );

  const handleDragEnd = async ({ active, over }) => {
    if (!over) return;

    const oldIndex = dashboards.findIndex(
      (tab) => tab.dashboard.dashboardId === active.id,
    );
    const newIndex = dashboards.findIndex(
      (tab) => tab.dashboard.dashboardId === over.id,
    );

    if (oldIndex !== -1 && newIndex !== -1 && oldIndex !== newIndex) {
      await updateDashboardPositionsAfterDrag({
        dashboards,
        oldIndex,
        newIndex,
        identityId,
        updateDashboardPosition,
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.buttons}>
          <DashboardTabsHomeButton />
          <DashboardTabsAddButton />
        </div>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={dashboards.map((tab) => tab.dashboard.dashboardId)}
            strategy={horizontalListSortingStrategy}
          >
            {dashboards.map(({ dashboard, dashboardId }) => (
              <Draggable id={dashboard.dashboardId} key={dashboardId} classNameWithDragging={styles.dragging}>
                <DashboardTab
                  dashboard={dashboard}
                  dashboardIds={dashboardIds}
                  id={dashboardId}
                  isSelected={dashboardId === id}
                />
              </Draggable>
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};

export default DashboardTabsWrapper;
