import React, { createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { api } from '@/constants';
import { useBatchedRequests } from '@/hooks';

import { mergeIdentities } from '../store/slices/identitiesSlice';

const fetchIdentities = async (identityIds) => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set('identityIds', `{${identityIds.join(',')}}`);
  const url = `${api.hostname}/corporateapi/identity/identitiesGetv2?${urlSearchParams.toString()}`;

  const response = await fetch(url);
  if (response.ok) {
    const result = await response.json();
    return result;
  }

  return response.text();
};

const IdentityBatchedRequest = createContext(null);

export const IdentityBatchedRequestProvider = ({ children }) => {
  const dispatch = useDispatch();
  const onDone = (identities) => {
    dispatch(mergeIdentities(identities));
  };

  const identityBatchedRequests = useBatchedRequests({ callback: fetchIdentities, onDone });
  return (
    <IdentityBatchedRequest.Provider value={identityBatchedRequests}>
      {children}
    </IdentityBatchedRequest.Provider>
  );
};

export const useIdentityBatchedRequestsContext = () => useContext(IdentityBatchedRequest);
