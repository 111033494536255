import DescriptionIcon from '@mui/icons-material/Description';
import { Chip, Link } from '@mui/material';
import { styled } from '@mui/system';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';

import ErrorBoundary from '@/components/ErrorBoundary';
import { useScrollBlock } from '@/hooks';

import Modal from '../Modal';
import Stepper from '../Stepper';
import styles from './CreativesModal.module.scss';
import CreativesModalStepOne from './CreativesModalStepOne';
import CreativesModalStepTwo from './CreativesModalStepTwo';
import getMissingRequiredParams from './getMissingRequiredParams';
import templatesByKey from './templates';

const BetaChip = styled(Chip)({
  position: 'relative',
  top: '-0.75em',
  left: '-0.5em',
  fontSize: '11px',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontVariantCaps: 'small-caps',
  letterSpacing: '1px',
  color: 'blue',
  backgroundColor: 'transparent',
  padding: 0,
});

const CreativesModal = ({ availableCreatives, baseParams, close }) => {
  const [blockScroll, allowScroll] = useScrollBlock();
  const [activeIndex, setActiveIndex] = useState(0);
  const selectedTemplateKeyState = useState(availableCreatives.length === 1 ? availableCreatives[0] : null);
  const [selectedTemplateKey] = selectedTemplateKeyState;

  const userDataState = useState({});
  const [userData] = userDataState;

  const formRef = useRef(null);

  const steps = useMemo(
    () => [
      {
        label: 'Setup',
        isDisabled: !selectedTemplateKey,
        render: () => (
          <CreativesModalStepOne
            availableCreatives={availableCreatives}
            baseParams={baseParams}
            formRef={formRef}
            selectedTemplateKeyState={selectedTemplateKeyState}
            userDataState={userDataState}
          />
        ),
        validate: () => {
          const form = formRef.current;
          if (form) {
            const formIsValid = form.validateForm();
            if (!formIsValid) {
              return false;
            }
          }

          const template = templatesByKey[selectedTemplateKey];
          if (template?.required) {
            const missingRequiredParams = getMissingRequiredParams(
              baseParams,
              template.required,
            );
            if (missingRequiredParams.length) {
              return false;
            }
          }

          return true;
        },
      },
      {
        label: 'Result',
        render: () => (
          <CreativesModalStepTwo
            baseParams={baseParams}
            template={templatesByKey[selectedTemplateKey]}
            userData={userData}
          />
        ),
      },
    ],
    [selectedTemplateKey, userData],
  );

  useEffect(() => {
    blockScroll();

    return () => {
      allowScroll();
    };
  }, []);

  return (
    <ErrorBoundary>
      <Modal
        isOpen
        close={close}
        title={(
          <div className={styles.title}>
            <div className={styles.titleLeft}>
              <span>Creatives</span>
              <BetaChip color="primary" label="Beta" size="small" />
            </div>
            <Link
              className={styles.titleLink}
              href="https://docs.qloo.com/docs/creatives"
              target="_blank"
              rel="noreferrer"
            >
              <DescriptionIcon className={styles.titleLinkIcon} />
              Documentation
            </Link>
          </div>
        )}
        renderContent={() => (
          <div className={styles.container}>
            <Stepper
              activeIndex={activeIndex}
              className={styles.stepper}
              containerClassName={styles.stepperContent}
              onChange={setActiveIndex}
              onSubmit={close}
              steps={steps}
              submitLabel="Done"
            />
          </div>
        )}
      />
    </ErrorBoundary>
  );
};

export default CreativesModal;
