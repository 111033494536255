import React from 'react';
import { Plus } from 'react-feather';

import Popover from '@/components/Popover';
import TabButton from '@/components/TabButton';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import tooltips from '@/utils/tooltips';

import styles from './DashboardTabs.module.scss';
import DashboardTabsAddButtonPanel from './DashboardTabsAddButtonPanel';

const DashboardTabsAddButton = () => (
  <Popover
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    renderContent={({ close }) => <DashboardTabsAddButtonPanel close={close} />}
    renderCustomTrigger={({ open }) => (
      <TooltipInfo title={tooltips.dashboard.plus}>
        <div className={styles.addButtonWrapperHeight}>
          <TabButton
            onClick={open}
            sx={{
              height: '100%',
              width: 38,
              minWidth: 38,
            }}
          >
            <Plus size={18} />
          </TabButton>
        </div>
      </TooltipInfo>
    )}
  />
);

export default DashboardTabsAddButton;
