import React from 'react';

import EntityText from '@/components/EntityText';
import { paramNames } from '@/constants';

const getInterestsLabels = (baseQueryValue) => {
  const items = baseQueryValue[paramNames.omnisearch] || [];

  if (items.length) {
    return items.map((item, index) => <EntityText key={item.id} item={item} index={index} />);
  }

  return 'Anything';
};

export default getInterestsLabels;
