import { getHasPermission } from '@v1/hooks/useHasPermission';
import clsx from 'clsx';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CreativesModal from '@/components/CreativesModal';
import templates from '@/components/CreativesModal/templates';
import DashboardPermissionedControl from '@/components/DashboardPermissionedControl';
import OwnershipOverlay from '@/components/OwnershipOverlay';
import { USER_ROLES } from '@/constants/roles';
import { useOwnership, useToggle } from '@/hooks';

import MoreOptionsDashboard from '../../HomePage/MoreOptionsDashboard';
import { useDashboardActionsContext } from '../DashboardActionsProvider';
import { useDashboardVeltContext } from '../DashboardVeltProvider';
import getTitle from '../helpers/getTitle';
import QueryBuilder from '../QueryBuilder/QueryBuilder';
import QueryBuilderPopover from '../QueryBuilderPopover';
import styles from './DashboardHeader.module.scss';

const DashboardHeader = ({ dashboard, stickyView }) => {
  const isCreativesModalOpenToggle = useToggle();
  const { getQueryBuilderVeltLocation, setVeltLocation } = useDashboardVeltContext();
  const dashboardActions = useDashboardActionsContext();
  const { baseParams, id } = dashboard;

  // Ownership
  const tag = `${id}-header`;
  const headerOwnership = useOwnership(tag);

  const navigate = useNavigate();
  const { dashboardIds } = useSelector((state) => state.dashboardTabs);
  // Clear ownership on unmount
  useEffect(() => () => {
    headerOwnership.clear();
  }, []);

  // Popover
  const [selectedPopover, setSelectedPopover] = useState(null);
  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'demoPopper',
  });

  const handleOpen = useCallback((popover) => (e) => {
    setSelectedPopover(popover);
    popupState.open(e);
  }, [popupState]);

  const handleClose = () => {
    setSelectedPopover(null);
    popupState.close();
  };

  const handleRedirectDashboard = () => {
    const otherDashboard = Object.values(dashboardIds).find((d) => d.id !== id && !d.deletedAt);
    if (otherDashboard && otherDashboard !== id) {
      navigate(`/dashboards/view/${otherDashboard}`);
    } else {
      navigate('/dashboards/view/');
    }
  };

  const handleChange = ({ key, value }) => {
    dashboardActions.changeBaseParam({ key, value });
  };

  const handleReset = (paramNames) => {
    const nextBaseParams = { ...baseParams };
    paramNames.forEach((paramName) => {
      nextBaseParams[paramName] = null;
    });

    dashboardActions.updateBaseParams(nextBaseParams);
  };

  const veltLocation = useMemo(
    () => getQueryBuilderVeltLocation({ dashboardId: dashboard.id }),
    [dashboard.id],
  );

  useEffect(
    () => {
      setVeltLocation(veltLocation);
    },
    [veltLocation],
  );

  const { identity } = useSelector((state) => state.app);
  const availableCreatives = useMemo(
    () => {
      const templateKeys = Object.keys(templates);
      const hasWildCard = getHasPermission({ identity, requiredPermission: 'feature.creatives._all' });
      if (hasWildCard) {
        return templateKeys;
      }

      return templateKeys
        .filter((template) => getHasPermission({ identity, requiredPermission: `feature.creatives.${template}` }));
    },
    [identity],
  );

  const shouldShowCreatives = Boolean(availableCreatives.length);

  return (
    <OwnershipOverlay tag={tag}>
      <div className={clsx(['comment-able', styles.container, { [styles.containerSticky]: stickyView }])}>
        <div className={styles.queryBuilder}>
          <DashboardPermissionedControl
            dashboardId={dashboard.id}
            requiredPermissions={[USER_ROLES.EDITOR]}
          >
            {stickyView && (
              <div className={clsx(styles.baseQuery, styles.baseQuerySticky)}>{getTitle(dashboard)}</div>
            )}
            <QueryBuilder params={baseParams} onOpen={handleOpen} veltLocation={veltLocation}>
              {({ DemographicsComponent, InterestsComponent, LocationComponent }) => (
                <div className={clsx(styles.baseQuery, { [styles.baseQuerySticky]: stickyView })}>
                  <DemographicsComponent
                    color="orange"
                    className={clsx(styles.picker, { [styles.pickerSticky]: stickyView })}
                  />
                  &nbsp;
                  <div>from</div>
                  &nbsp;
                  <LocationComponent
                    color="purple"
                    className={clsx(styles.picker, { [styles.pickerSticky]: stickyView })}
                  />
                  &nbsp;
                  <div>that like{baseParams?.gender ? '' : 's'}</div>
                  &nbsp;
                  <InterestsComponent
                    color="blue"
                    className={clsx(styles.picker, { [styles.pickerSticky]: stickyView })}
                  />
                </div>
              )}
            </QueryBuilder>
          </DashboardPermissionedControl>
          <QueryBuilderPopover
            lock={headerOwnership.set}
            popupState={popupState}
            onChange={handleChange}
            onClose={handleClose}
            onReset={handleReset}
            selectedPopover={selectedPopover}
            params={baseParams}
            releaseLock={headerOwnership.clear}
          />
        </div>
        <MoreOptionsDashboard
          dashboard={dashboard}
          onConfirmDelete={handleRedirectDashboard}
          onOpenCreativesModal={isCreativesModalOpenToggle.on}
          showFavorite
          shouldShowCreatives={shouldShowCreatives}
        />
      </div>
      {isCreativesModalOpenToggle.value
        ? (
          <CreativesModal
            availableCreatives={availableCreatives}
            baseParams={baseParams}
            close={isCreativesModalOpenToggle.off}
          />
        )
        : null}
    </OwnershipOverlay>
  );
};

export default DashboardHeader;
