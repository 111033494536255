import {
  Box,
  Button as MuiButton,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/system';
import { List, TrendingUp } from 'react-feather';
import { CgMagnet } from 'react-icons/cg';

const StyledButton = styled(MuiButton)(({ theme, selected }) => ({
  padding: 0,
  ...(selected && {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,

    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  }),
}));

const HeatmapToggle = ({ value, onChange }) => {
  const options = [
    { id: 'affinity', name: 'Affinity', label: <CgMagnet size={18} /> },
    { id: 'popularity', name: 'Popularity', label: <TrendingUp size={18} /> },
    { id: 'affinity_rank', name: 'Affinity Rank', label: <List size={18} /> },
  ];

  return options.map((option) => (
    <StyledButton
      key={option.id}
      onClick={() => onChange(option.id)}
      selected={value === option.id}
      shape="square"
    >
      <Tooltip title={option.name}>
        <Box display="flex" padding="6px 8px">{option.label}</Box>
      </Tooltip>
    </StyledButton>
  ));
};

export default HeatmapToggle;
