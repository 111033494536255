/* eslint-disable max-len */
import ReactMarkdown from 'react-markdown';

import getDemographicsLabel from '@/utils/getDemographicsLabel';
import tryParseJson from '@/utils/tryParseJson';

import getPersonaResultMarkdown from '../shared/getPersonaResultMarkdownRealEstate';
import personaForm from '../shared/personaForm';
import personaInsights from '../shared/personaInsights';

const schema = {
  strict: true,
  required: [
    'who_we_are',
    'what_drives_us',
    'ticks',
    'pain_points',
    'goals_and_needs',
    'day_in_the_life',
    'ideas',
    // 'ideal_amenities',
  ],
  additionalProperties: false,
  properties: {
    who_we_are: {
      type: 'string',
      $comment: "Write a detailed description of the persona's background, including their profession, age, interests, and daily habits. Highlight their passions, key personality traits, and how they interact with their living space. Provide specific brand affinities or lifestyle cues that define them.",
    },
    what_drives_us: {
      type: 'string',
      $comment: 'Describe the core motivations and driving factors for the persona. Explain what inspires them, what they value in their environment, and how they connect with their community. Highlight emotional and functional needs driving their decisions.',
    },
    ticks: {
      type: 'string',
      $comment: 'Describe what makes them tick, what makes them eccentric.',
    },
    pain_points: {
      type: 'string',
      $comment: 'List the main challenges or frustrations the persona experiences in their living environment. These should be specific and tied to their lifestyle, habits, and expectations from their office or community space.',
    },
    goals_and_needs: {
      type: 'array',
      $comment: 'Listed descriptions of goals and needs',
      items: {
        type: 'string',
        description: 'string',
        $comment: "Outline the persona's primary goals and needs related to their living space and community. Include both practical and emotional needs, focusing on what would enhance their overall satisfaction and experience.",
      },
      // "type": "string",
      // "$comment": "Outline the persona's primary goals and needs related to their living space and community. Include both practical and emotional needs, focusing on what would enhance their overall satisfaction and experience."
    },
    day_in_the_life: {
      type: 'string',
      $comment: "Write a narrative describing a typical day in the persona's life. Highlight key moments, habits, and interactions they have with their living space and community. Use specific examples of activities and brand interactions where applicable.",
    },
    ideas: {
      type: 'array',
      $comment: 'Descriptions of the ideal amenities for the persona',
      items: {
        type: 'string',
        $comment: "Propose three tailored office or community ideas designed to meet the persona's needs and preferences. Each idea should include a clear motivation explaining how it addresses their lifestyle and enhances their experience.",
      },
    },
  },
};
const mapEntity = (entity) => `Entity: ${entity.name}, Subject: ${entity.subtype}, Score: ${entity.query.affinity}`;

const mapTag = (entity) => `Tag Genre: ${entity.name}, Subject: ${entity.subtype}, Score: ${entity.query.affinity}`;

const index = {
  name: 'Real Estate Focused Persona',
  description: 'Real Estate focused personas for property teams',
  form: personaForm,
  insights: personaInsights,
  render: ({ result }) => {
    const markdown = getPersonaResultMarkdown(tryParseJson(result.slice(7, -3)) || []);
    return (
      <ReactMarkdown>
        {markdown}
      </ReactMarkdown>
    );
  },
  runner: async ({
    baseParams, entities, fetchGpt, tags, userData,
  }) => {
    const { personaCount } = userData;
    const demographicsLabel = getDemographicsLabel(baseParams);
    const prompt = `
    As a persona developer, your task is to create ${personaCount} distinct personas for an office building. Each persona must:
    1. Be grounded in the provided data, reflecting regional, locational, and cultural nuances. Highlight only the dominant location when relevant (≥0.95 affinity). If a geographic tag is categorized as a trend reference it as a cultural or stylistic influence rather than a residence.
    2. Focus on practical traits, motivations, and behaviors related to office living.

    **Input Data**:
    - Keep the ${demographicsLabel} demographic in mind, however it has already influenced the input data.
    - Tags: ${tags.map(mapTag).join(' ')}
    - Entities: ${entities.map(mapEntity).join(' ')}

    **Persona Instructions**:
    - Base each persona entirely on the provided tags, entities, and affinity scores. Directly reference tags and entities and their affinity scores in a way that justifies their characteristics, lifestyle, or preferences.
    - Every tag or entity mentioned must include its affinity score as evidence of relevance
    - Avoid fictional details like names, backstories, or unsupported activities.
    - Justify all interests by linking them to the data (e.g., "This persona connects with [Tag X] (score: 0.95) because of its emphasis on [specific lifestyle or habit].").
    - Highlight key motivations, preferences, challenges, and needs relevant to offices.

    **Sections**:
    - Include a “day in the life” section for these different personas that is guided by key traits derived from the provided data.\
    - Integrate some meaningful and captivating micro-moments in the as this will help bring the personas to life in this section.\
    1. **Profile Overview**: Summarize key traits and characteristics.
    2. **Behaviors and Preferences**: Describe specific patterns and habits tied to the data.
    3. **Challenges and Goals**: Highlight office-related challenges and goals.
    4. **Recommendations**: Provide three actionable ideas for enhancing the tenant experience, with justifications.
    5. **Community Interaction**: Explain how the persona might interact with others and foster collaboration.
    6. **Partnership Ideas**: Suggest partnerships or incentives to attract and retain tenants.

    **Output Requirements**:
    - Return all ${personaCount} personas only as a **JSON array** using this schema: ${JSON.stringify(schema)}
    - Maintain a professional tone without unnecessary embellishments or fictional quotes.
    `;

    // **Demographic Focus**:
    // - Develop personas for three generational groups:
    //   - **Gen Z (24-29)**: Young professionals starting their careers.
    //   - **Millennials (30-44)**: Mid-career professionals balancing work and life.
    //   - **Gen X and Boomers (45+)**: Experienced professionals in leadership or near retirement.

    // **Sections**:
    // 1. **Profile Overview**:
    //    - A concise summary of the persona’s characteristics and key traits derived from the provided data.
    // 2. **Behaviors and Preferences**:
    //    - Specific patterns, habits, or preferences tied to the tags and entities.
    // 3. **Challenges and Goals**:
    //    - Observable challenges they may face in an office building environment and their goals for office living.
    // 4. **Office Development Recommendations**:
    //    - Three actionable ideas that an office developer could implement to enhance the experience for this persona type, including the motivations behind each idea. These ideas must be derived solely from the provided data.
    // 5. **Community Interaction**:
    //    - Insights into how this persona might interact with others in the same office community, fostering a sense of collaboration and belonging.
    // 6. **Partnership Opportunities**:
    //    - Suggestions for partnerships or incentives that the office owner can explore to attract and motivate tenants based on persona traits.

    // **Formatting Requirements**:
    // - Return all personas in a **JSON array** structured according to this schema: ${JSON.stringify(schema)}
    // - Ensure a professional tone without fictional quotes, first-person perspectives, or unnecessary embellishments.
    // `;
    //     // const prompt = `
    // As a persona developer with the ability to create ${personaCount} distinct, goal-directed personas that are grounded in the provided data, your task is to ensure:
    // 1. **Data Integrity**: Each persona directly reflects and is rooted in the provided data, highlighting regional, locational, and cultural nuances when present.

    // **Input Data**:
    // - Tags: ${tags.map(mapTag).join(' ')}
    // - Entities: ${entities.map(mapEntity).join(' ')}

    // **Persona Instructions**:
    // - Personas must be entirely based on the provided tags, entities, and affinity scores.
    // - Avoid creative archetypes, life stories, names, or fictionalized personal details.
    // - Do not repeat tags, entities, or venues across personas.
    // - Each persona should focus on practical traits, motivations, and observable behaviors related to office living, including:
    //   - What motivates them or drives their behavior.
    //   - Key interests, preferences, and needs based on the provided data.
    //   - Frustrations or challenges they might face in an office setting.
    // - Personas should guide an office owner in maximizing tenant experience and supporting attraction and retention efforts.

    // **Sections**:
    // 1. **Profile Overview**:
    //    - A concise summary of the persona’s characteristics and key traits derived from the provided data.
    // 2. **Behaviors and Preferences**:
    //    - Specific patterns, habits, or preferences tied to the tags and entities.
    // 3. **Challenges and Goals**:
    //    - Observable challenges they may face in an office setting and their goals for office living.
    // 4. **Office Development Recommendations**:
    //    - Three actionable ideas that an office developer could implement to enhance the experience for this persona type, including the motivations behind each idea. These ideas must be derived solely from the provided data.
    // 5. **Community Interaction**:
    //    - Insights into how this persona might interact with others in the same office community, fostering a sense of collaboration and belonging.
    // 6. **Partnership Opportunities**:
    //    - Suggestions for partnerships or incentives that the office owner can explore to attract and motivate residents based on persona traits.

    // **Formatting Requirements**:
    // - Return all personas in a **JSON array** structured according to this schema: ${JSON.stringify(schema)}
    // - Ensure a professional tone without fictional quotes, first-person perspectives, or unnecessary embellishments.
    // `;

    // const prompt = `
    // As a persona developer with the ability to create ${personaCount} distinct goal-directed personas that cut straight to the nitty-gritty.\
    // Each persona must directly reflect and be grounded in the provided data. Ensure that any regional, locational, cultural nuances already present in the input data are highlighted.
    //  **Input Data**:
    //   - Tags: ${tags.map(mapTag).join(' ')}
    //   - Entities: ${entities.map(mapEntity).join(' ')}
    // 2. **Persona Instructions**:
    // - Each persona must be **entirely based** on the provided tags, entities, and affinity scores.\
    // - Avoid creative archetypes or backstories. Do not repeat tags, entities, or venues across personas.\
    // - Connect entities and tags to their affinity scores explaining why each is relevant to the persona's traits and lifestyle.
    // - These personas should focus on their motivations or what makes them thrive, what are the little things that keep them inspired, how they feel from the inside and the outside, what their character traits are, what makes them tick, what makes them eccentric, pain points or frustrations that could manifest in an office setting given their interests, and their overall needs and goals that are observed from the brands as it relates to office living.\
    // - The goal for an office owner is to maximize the tenant experience at the office building. This requires in depth understanding of what your residents want and these personas are being developed to help the attraction and retention of prospects and incumbents that align with these personas.\
    // 3. **Sections**:
    // - Include a “day in the life” section for these different personas that is guided by the brands in the attached file.\
    //  Integrate some meaningful and captivating micro-moments in the as this will help bring the personas to life in this section.\
    //  Also weave in some fictional but realistic quotes from each persona. For each of the personas,\
    //  Include three ideas that an office developer could consider implementing to enhance the experience of each of these persona types\
    //  and what the motivations for these ideas are for each persona with guidance only from the attached file.\
    // - Conclude with a recommendation that blends the persona interests and highlights 3 ideas that will enhance experiences for these personas.\
    //  Highlight how these different personas might interact within the same office community and guide how a sense of community can be developed and\
    //  curated with the insights from the attached file.\
    // - Generate some ideas around partnerships or incentive opportunities that an owner can explore to entice and motivate residents.\
    // - Return all personas in a **JSON array** structured according to this schema: ${JSON.stringify(schema)}
    // `;

    try {
      const gptResult = await fetchGpt({ prompt });
      return gptResult;
    } catch (e) {
      return 'Error';
    }
  },
};

export default index;
