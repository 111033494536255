import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';

import { USER_ROLES } from '@/constants/roles';
import { useActiveDashboard, useScrollBlock } from '@/hooks';
import { useGetDashboardQuery, useGetDashboardRecentQuery, useShareDashboardMutation } from '@/store/apis/dashboardsApi';
import { useGetOrganizationsQuery, useIdentitySubordinatesGetQuery } from '@/store/apis/identityApi';
import getIsQlooUser from '@/utils/getIsQlooUser';
import getIsUserInOrganization from '@/utils/getIsUserInOrganization';

import { removeDuplicates } from '../helpers/dashboardHelpers';
import useUserIdentity from '../hooks/useUserIdentity';
import DashboardShareWithSkeleton from '../Skeleton/DashboardShareWithSkeleton';
import CustomInputWithChips from './CustomInputWithChips';
import UserSection from './UserSection';

const StyledAutocomplete = styled(Autocomplete)(() => ({
  width: 150,
  '& .MuiFormControl-root': {
    width: '100%',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'hsl(var(--hover-light-border))',
    '& fieldset': { border: 'none' },
    '&:hover fieldset': { borderColor: 'transparent' },
    fontSize: '16px',
    padding: '0 8px',
  },
  '& .MuiInputBase-input': {
    fontSize: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '&:hover .MuiOutlinedInput-root': {
    backgroundColor: 'hsl(var(--hover-border))',
  },
  '& .MuiAutocomplete-paper': {
    fontSize: '12px',
  },
}));

const ShareWithDialog = ({ dashboard, open, onClose }) => {
  // TODO: Check if dashboard and activeDashboard are both needed
  const activeDashboard = useActiveDashboard();
  const userIdentity = useUserIdentity(activeDashboard);
  const { byId } = useSelector((state) => state.identities);

  const [blockScroll, allowScroll] = useScrollBlock();

  const [inputValue, setInputValue] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [selectedOrg, setSelectedOrg] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedOrgsWithAllUsers, setSelectedOrgsWithAllUsers] = useState([]);
  const [permission, setPermission] = useState(`{${USER_ROLES.VIEWER}}`);
  const [isMessageError, setIsMessageError] = useState(false);

  const { identityId: organizationId } = selectedOrg;

  const { refetch: refetchCurrentDashboard } = useGetDashboardQuery({ id: dashboard?.id }, { skip: !dashboard });
  const { data: organizationsData } = useGetOrganizationsQuery({});
  const { refetch: refetchDashboardRecent } = useGetDashboardRecentQuery({});

  const {
    data: organizationPersonnelDashboard,
    isLoading: isLoadingOrganizationPersonnelDashboard,
  } = useIdentitySubordinatesGetQuery({ identityId: organizationId }, { skip: !organizationId });
  const [shareDashboard, { isLoading: isLoadingShareDashboard }] = useShareDashboardMutation();

  const peopleWithAccess = useMemo(() => {
    return activeDashboard?.identityLinks
      ?.filter((identityLink) => identityLink.identityId !== userIdentity?.identityId && byId[identityLink.identityId])
      ?.map((identityLink) => ({
        ...byId[identityLink.identityId],
        ...identityLink,
      })) || [];
  }, [activeDashboard, userIdentity]);
  const orgWithAccess = useMemo(() => {
    if (organizationsData && organizationId) {
      return peopleWithAccess
        .filter((person) => organizationsData
          .some((org) => org.identityId === person.identityId));
    }
    return [];
  }, [peopleWithAccess, organizationsData, organizationId]);

  const modifiedSelectedOrg = useMemo(() => ({
    ...selectedOrg,
  }), [selectedOrg, organizationId]);
  useMemo(() => {
    if (organizationsData && userIdentity) {
      setSelectedOrg(
        organizationsData
          .find((organization) => getIsUserInOrganization({
            organizationIdentityId: organization.identityId, userIdentity,
          }))
        || organizationsData[0],
      );
    }
  }, [organizationsData, userIdentity]);
  const isSelectedOrgChecked = useMemo(() => {
    return selectedOrgsWithAllUsers.some((org) => org.identityId === organizationId);
  }, [selectedOrgsWithAllUsers, organizationId]);
  const uniqueData = useMemo(
    () => removeDuplicates(organizationPersonnelDashboard || [], 'identityId'),
    [organizationPersonnelDashboard],
  );

  const isQlooUser = useMemo(() => Boolean(getIsQlooUser(userIdentity)), [userIdentity]);

  useEffect(() => {
    if (isMessageError) {
      const timer = setTimeout(() => {
        setIsMessageError(false);
      }, 5000);
      return () => clearTimeout(timer);
    }

    return () => { };
  }, [isMessageError]);

  useEffect(() => {
    if (open) {
      blockScroll();
    } else {
      allowScroll();
    }

    return () => allowScroll();
  }, [open]);

  const handleBackInvite = () => {
    setSearchInput('');
    setSelectedUsers([]);
    setSelectedOrgsWithAllUsers([]);
  };

  const handleClose = () => {
    setPermission(`{${USER_ROLES.VIEWER}}`);
    handleBackInvite();
    onClose();
  };

  const handleConfirm = async () => {
    setIsMessageError(false);

    const allIdentityIds = [
      ...selectedUsers.map((u) => u.identityId),
      ...selectedOrgsWithAllUsers.flatMap((org) => org.users.map((u) => u.identityId)),
    ];

    try {
      const result = await shareDashboard({
        dashboardId: dashboard.dashboardId,
        identityIds: `{${allIdentityIds.join(',')}}`,
        grantedPermissionTagIds: permission,
      });
      if (result.error) {
        setIsMessageError(true);
        throw new Error('Error sending invitation');
      }
      await refetchDashboardRecent();
      await refetchCurrentDashboard();
      handleBackInvite();
    } catch (error) {
      setIsMessageError(true);
    }
  };
  const handleSelectOrg = (org) => {
    setSelectedOrg(org);
  };

  const usersWithoutAccess = uniqueData.filter(

    (user) => !peopleWithAccess.some((accessUser) => accessUser.identityId === user.identityId)
      && user.fullName.toLowerCase().includes(searchInput.toLowerCase()),
  );

  const currentOrgUsersWithAccess = (peopleWithAccess || [])
    .filter((person) => !orgWithAccess
      .some((org) => org.identityId === person.identityId)
      && person
        .fullName
        .toLowerCase()
        .includes(searchInput.toLowerCase()))
    .map((user) => ({
      ...user,
      grantedPermissionTagIds: peopleWithAccess.find((personWithAccess) => personWithAccess.identityId === user.identityId)
        .grantedPermissionTagIds,
    }));

  const filteredOrgWithAccess = orgWithAccess.filter(
    (u) => u.fullName.toLowerCase().includes(searchInput.toLowerCase()),
  );

  const getUpdatedSelectedUsers = (user, users) => (users.some((selectedUser) => selectedUser.identityId === user.identityId)
    ? users.filter((selectedUser) => selectedUser.identityId !== user.identityId)
    : [...users, user]);

  const handleUserClick = (user) => {
    setSearchInput('');
    if (user.identityId === modifiedSelectedOrg.identityId) {
      setSelectedUsers((prevUsers) => getUpdatedSelectedUsers(user, prevUsers));
      setSelectedOrgsWithAllUsers((prevOrgs) => prevOrgs.filter((org) => org.identityId !== modifiedSelectedOrg.identityId));
    } else {
      isSelectedOrgChecked
        ? setSelectedOrgsWithAllUsers((prevOrgs) => prevOrgs.map((org) => (org.identityId === organizationId
          ? {
            ...org,
            users: getUpdatedSelectedUsers(user, org.users),
          }
          : org)))
        : setSelectedUsers((prevUsers) => getUpdatedSelectedUsers(user, prevUsers));
    }
  };

  const isUserSelected = (user, users) => users.some((selectedUser) => selectedUser.identityId === user.identityId);
  const checkSelected = (user) => (isSelectedOrgChecked
    ? selectedOrgsWithAllUsers.some((org) => org.identityId === organizationId
      && isUserSelected(user, org.users))
    : isUserSelected(user, selectedUsers));
  const renderUserSection = (title, users, showSelectPermission = false) => {
    if (users.length > 0) {
      return (
        <UserSection
          title={title}
          dashboardId={dashboard.dashboardId}
          users={users}
          onUserClick={handleUserClick}
          checkSelected={checkSelected}
          showSelectPermission={showSelectPermission}
        />
      );
    }
    return null;
  };

  const handleOrgChange = useCallback((event, newValue) => {
    const foundOrg = organizationsData.find((org) => org.identityId === newValue.identityId);
    handleSelectOrg(foundOrg);
  }, [organizationsData]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          width: 600,
        },
      }}
    >
      <DialogTitle display="flex" justifyContent="space-between" sx={{ padding: '16px', paddingBottom: '8px' }}>
        <span>Shared with</span>
        <IconButton onClick={handleClose}><X /></IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        {isMessageError && (
          <Alert severity="error" sx={{ width: '100%' }}>
            Sorry, something unexpected happened, please try again.
          </Alert>
        )}
        <Box
          minWidth={550}
          height="100%"
          pt={2}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="space-between"
          position="relative"
        >
          <Box width="100%" maxHeight="100%" display="flex" flexDirection="column" gap={2} paddingBottom={4}>
            <Box display="flex" pl={2} pr={2} gap={2}>
              <Box display="flex" flex={1} flexDirection="column" gap={0.5}>
                <CustomInputWithChips
                  dashboardId={dashboard.dashboardId}
                  isQlooUser={isQlooUser}
                  selectedUsers={selectedUsers}
                  selectedOrgs={selectedOrgsWithAllUsers}
                  setSelectedUsers={setSelectedUsers}
                  setSelectedOrgs={setSelectedOrgsWithAllUsers}
                  setPermission={setPermission}
                  setSearchInput={setSearchInput}
                  searchInput={searchInput}
                />
              </Box>
              {isQlooUser && (
                <Box margin="auto" display="flex" flexDirection="column" gap={0.5}>
                  <StyledAutocomplete
                    disableClearable
                    options={organizationsData || []}
                    value={selectedOrg}
                    inputValue={inputValue}
                    getOptionLabel={(option) => option.fullName}
                    renderOption={(props, option) => (
                      <li {...props} key={option.identityId} style={{ fontSize: 14 }}>
                        {option.fullName}
                      </li>
                    )}
                    onChange={handleOrgChange}
                    onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                    renderInput={(params) => <TextField {...params} sx={{ width: 130 }} />}
                  />
                </Box>
              )}
            </Box>

            <Box height="100%" overflow="auto" display="flex" flexDirection="column" gap={1.5}>
              {isLoadingOrganizationPersonnelDashboard
                ? <DashboardShareWithSkeleton />
                : currentOrgUsersWithAccess.length === 0 && usersWithoutAccess.length === 0 && !selectedOrg ? (
                  <Typography margin={2} color="textSecondary">No users found</Typography>
                ) : (
                  <>
                    {!orgWithAccess.some((org) => org.identityId === organizationId)
                      && renderUserSection('Organization', [modifiedSelectedOrg])}

                    {renderUserSection('Organizations with access', filteredOrgWithAccess, true)}

                    {(searchInput === '' && currentOrgUsersWithAccess.length < 1 && !isSelectedOrgChecked)
                      && renderUserSection('Suggestions', usersWithoutAccess.slice(0, 3))}

                    {((peopleWithAccess.length > 0)
                      || (searchInput !== '' && peopleWithAccess.length > 0) || isSelectedOrgChecked)
                      && renderUserSection('People with access', currentOrgUsersWithAccess, true)}
                    {(searchInput || isSelectedOrgChecked) && renderUserSection('Not in dashboard', usersWithoutAccess)}
                  </>
                )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={selectedUsers.length === 0 && selectedOrgsWithAllUsers.length === 0}
          loading={isLoadingShareDashboard}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareWithDialog;
