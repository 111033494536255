import {
  Box, Card, Skeleton,
} from '@mui/material';
import React from 'react';

const DashboardCardSkeleton = () => {
  const rows = Array.from({ length: 5 }, (_, index) => index);

  return (
    <Box display="flex" flexWrap="wrap" width="100%" gap={3}>
      {rows.map((index) => (
        <Card key={`skeleton-card-${index}`} sx={{ width: 345, minHeight: 370, boxShadow: '6px 10px 11px -10px rgba(0,0,0,0.75)' }}>
          <Box display="flex" alignItems="center" justifyContent="space-between" p={2} key={`box-header-${index}`}>
            <Skeleton variant="circular" width={30} height={30} />
            <Box key={`box-text-${index}`}>
              <Skeleton variant="rectangular" width={200} height={20} sx={{ marginBottom: 1 }} />
              <Skeleton variant="rectangular" width={200} height={20} />
            </Box>
            <Skeleton variant="rectangular" width={24} height={24} />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" p={2} key={`box-image-${index}`}>
            <Skeleton variant="rectangular" width="100%" height={240} />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" p={2} key={`box-footer-${index}`}>
            <Box display="flex" alignItems="center" gap={1} key={`box-footer-inner-${index}`}>
              <Skeleton variant="circular" width={24} height={24} />
              <Skeleton variant="text" width={100} height={20} />
            </Box>
            <Skeleton variant="rectangular" width={24} height={24} />
          </Box>
          <Box p={2} key={`box-texts-${index}`}>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="60%" />
          </Box>
        </Card>
      ))}
    </Box>
  );
};

export default DashboardCardSkeleton;
