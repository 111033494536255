import {
  Box, Card, CardActionArea, Tooltip, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import { ellipsisText } from '@/utils/format';
import formatCategoryUrnToCssVar from '@/utils/formatCategoryUrnToCssVar';
import { formatEntityCategories } from '@/utils/tagCategories';

import EntityImage from '../EntityImage';
import styles from './EntityItem.module.scss';

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => !['categoryUrnCss', 'selected'].includes(prop),
})(({
  categoryUrnCss,
  selected,
  theme,
}) => ({
  boxShadow: 'none',
  border: '1px solid',
  borderColor: selected ? `var(--category-${categoryUrnCss})` : theme.palette.gray.main,
  borderRadius: 0,
  '&:hover': {
    backgroundColor: `var(--category-${categoryUrnCss}-secondary)`,
  },
}));

const EntityItem = ({
  entity,
  className,
  selected,
  minified,
  ...props
}) => {
  const categoryUrnCss = formatCategoryUrnToCssVar(entity.subtype || entity.types[0]);

  if (minified) {
    return (
      <StyledCard className={clsx(styles.entityMini, className)}>
        <Box display="flex" height="100%">
          <div className={clsx(styles.entityImageContainer, styles.entityImageContainerMini)}>
            <EntityImage
              entity={entity}
              placeholder={entity.name}
              className={styles.entityImageMini}
            />
          </div>
          <div className={clsx(styles.entityContent, styles.entityContentMini)}>
            <Typography fontWeight="bold" fontSize="small">
              {ellipsisText(entity.name, 40)}
            </Typography>
          </div>
        </Box>
      </StyledCard>
    );
  }

  return (
    <Tooltip title={entity.disambiguation} placement="bottom" arrow>
      <StyledCard categoryUrnCss={categoryUrnCss} selected={selected} className={styles.entity}>
        <CardActionArea {...props}>
          <Box display="flex" height="72px">
            <div className={styles.entityImageContainer}>
              <EntityImage
                className={styles.entityImage}
                entity={entity}
                placeholder={entity.name}
              />
            </div>
            <div className={styles.entityContent}>
              <Typography fontWeight="bold">
                {entity.name}
              </Typography>
              <Typography sx={{ color: 'muted.contrastText' }}>
                {entity.disambiguation || ' '}
              </Typography>
              <Typography
                fontWeight="bold"
                textTransform="capitalize"
                color={`var(--category-${categoryUrnCss})`}
              >
                {formatEntityCategories(entity)}
              </Typography>
            </div>
          </Box>
        </CardActionArea>
      </StyledCard>
    </Tooltip>
  );
};

export default EntityItem;
