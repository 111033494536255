import AddIcon from '@mui/icons-material/Add';
import {
  Box, Card, CircularProgress, Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import TooltipWarning from '@/components/Tooltip/TooltipWarning';

import { useCreateDashboardMutation } from '../../store/apis/dashboardsApi';
import DashboardCard from './DashboardCard/DashboardCard';

const DashboardSection = ({
  isAuthorized,
  data,
  handleClickToRedirect,
  title,
  valueFilter,
}) => {
  const navigate = useNavigate();
  const [createDashboard, { isLoading: isCreating }] = useCreateDashboardMutation();

  const handleCreate = async () => {
    const { data: newData } = await createDashboard();
    navigate(`/dashboards/view/${newData.id}`);
  };

  const shouldShowTitle = !valueFilter || data.length > 0;
  const shouldShowCreateCard = !data.length && !valueFilter;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {shouldShowTitle
        && (
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h6" fontWeight={600}>{title}</Typography>
          </Box>
        )}
      <Box display="flex" flexWrap="wrap" alignItems="flex-start" gap={3}>
        {data.map((dashboard) => (
          <Box key={`box-card-wrapper-${dashboard.id}`} display="flex" flexDirection="column">
            <DashboardCard
              key={dashboard.id}
              data={dashboard}
              onClick={handleClickToRedirect}
            />
          </Box>
        ))}

        {shouldShowCreateCard && (
          <TooltipWarning
            placement="top-end"
            message="You don't have permission to create a new dashboard."
            conditionalRender={!isAuthorized}
          >
            <Card sx={{ width: 345, height: 372, cursor: 'pointer' }} onClick={isAuthorized && handleCreate}>
              <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                {isCreating ? <CircularProgress size={100} /> : <AddIcon color="disabled" sx={{ fontSize: '110px' }} />}
              </Box>
            </Card>
          </TooltipWarning>
        )}

      </Box>
    </Box>
  );
};

export default DashboardSection;
