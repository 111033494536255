import React from 'react';

import { USER_ROLES } from '@/constants/roles';
import { useUserDashboardPermissions } from '@/hooks';

import Tooltip from '../Tooltip';
import styles from './DashboardPermissionedControl.module.scss';

const DashboardPermissionedControl = ({
  requiredPermissions = [],
  dashboardId,
  hideIfUnauthorized = false,
  children,
}) => {
  const { permissionsBitMap, hasAdminPermission, hasLayoutPermission } = useUserDashboardPermissions(dashboardId);

  const userIsAuthorized = permissionsBitMap[USER_ROLES.OWNER]
    || requiredPermissions.some((permission) => permissionsBitMap[permission])
    || hasAdminPermission
    || hasLayoutPermission;

  const content = hideIfUnauthorized && !userIsAuthorized ? null : children;
  if (!userIsAuthorized) {
    return (
      <Tooltip
        arrow
        followCursor
        placement="top"
        title="You don't have access to this feature"
      >
        <div className={styles.container}>
          <div className={styles.inner}>
            {content}
          </div>
        </div>
      </Tooltip>
    );
  }

  return content;
};

export default DashboardPermissionedControl;
