import { useIdentify } from '@veltdev/react';
import { useSelector } from 'react-redux';
import stc from 'string-to-color';

const VeltIdentity = ({ children }) => {
  const { identity } = useSelector((state) => state.app);

  useIdentify({
    userId: identity.identityId,
    name: identity.fullName,
    email: identity.loginEmailAddress,
    hex: stc(identity.fullName),
  });

  return children;
};

export default VeltIdentity;
