import panelTypes from '@/constants/panelTypes';

function getPanelHeaderTitle(panelType) {
  switch (panelType) {
    case panelTypes.explore:
      return 'Summary overview';
    case panelTypes.heatmap:
      return 'Heatmap';
    case panelTypes.locationExplorer:
      return 'Location Explorer';
    case panelTypes.freeformText:
      return 'Text';
    case panelTypes.table:
    default:
      return 'Entity Correlations';
  }
}

export default getPanelHeaderTitle;
