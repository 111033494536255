// @ts-nocheck
import { Box, Chip, Tooltip } from '@mui/material';
import React, { useCallback } from 'react';

import ChangePermissionUser from './ChangePermissionUser';

const CustomInputWithChips = ({
  dashboardId,
  selectedUsers,
  selectedOrgs,
  setSelectedUsers,
  setSelectedOrgs,
  setPermission,
  searchInput,
  setSearchInput,
  isQlooUser,
}) => {
  const handleInputChange = (event) => setSearchInput(event.target.value);

  const handleDeleteUser = useCallback((user) => {
    setSelectedUsers(selectedUsers.filter((u) => u.identityId !== user.identityId));
  }, [selectedUsers]);

  const handleDeleteOrg = useCallback((org) => {
    setSelectedOrgs(selectedOrgs.filter((o) => o.identityId !== org.identityId));
  }, [selectedOrgs]);

  const handlePermissionChange = (newPermission) => setPermission(newPermission);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      border="1px solid #ccc"
      borderRadius={1}
      padding={0.7}
      gap={0.5}
    >
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" flexWrap="wrap" maxHeight={145} overflow="auto">
          {selectedOrgs.map((org) => (
            <Tooltip key={org.identityId} title={org.name}>
              <Chip
                label={`${org.users.length} users from ${org.name}`}
                onDelete={() => handleDeleteOrg(org)}
                sx={{ marginRight: '4px', marginBottom: '4px' }}
              />
            </Tooltip>
          ))}
          {selectedUsers.map((user) => (
            <Tooltip
              key={user.identityId}
              title={(
                <Box display="flex" alignItems="center">
                  {user.fullName} - {user.loginEmailAddress}
                </Box>
              )}
            >
              <Chip
                label={user.fullName}
                onDelete={() => handleDeleteUser(user)}
                sx={{ marginRight: '4px', marginBottom: '4px', backgroundColor: 'hsl(var(--hover-light-border))' }}
              />
            </Tooltip>
          ))}
        </Box>
        <input
          value={searchInput}
          onChange={handleInputChange}
          placeholder={isQlooUser ? 'Search for user or org' : 'Search for user'}
          style={{
            flexGrow: 1,
            border: 'none',
            outline: 'none',
            padding: 8,
          }}
        />
      </Box>
      {selectedUsers?.length > 0 && (
        <Box>
          <ChangePermissionUser
            dashboardId={dashboardId}
            onPermissionChange={handlePermissionChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default CustomInputWithChips;
