import { Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import EntityItem from '../EntityItem';
import styles from './Omnisearch.module.scss';

const EntitiesList = ({
  entities,
  entitiesStartIndex,
  entitiesGridClassName,
  getItemProps,
  resetPadding,
  showMini,
  valueBitmap,
}) => {
  const theme = useTheme();
  return (
    <div
      className={clsx({
        [styles.entities]: true,
        [styles.entitiesMini]: showMini,
        [styles.resetPadding]: resetPadding,
      })}
    >
      <div className={clsx([styles.entitiesGrid, entitiesGridClassName])}>
        {entities?.length > 0 ? (entities.map((entity, i) => {
          return (
            <EntityItem
              key={entity.entity_id}
              entity={entity}
              selected={valueBitmap ? valueBitmap[entity.entity_id] : false}
              minified={showMini}
              {...(getItemProps ? getItemProps({
                index: entitiesStartIndex + i,
                item: entity,
              }) : null)}
            />
          );
        })
        ) : (
          <div className={styles.placeholder}>
            <Typography
              component="pre"
              padding="10px"
              borderRadius={0.5}
              maxWidth={400}
              color={theme.palette.text.primary}
              sx={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                backgroundColor: theme.palette.grey['50'],
              }}
            >
              No valid entities to display for this search.
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default EntitiesList;
