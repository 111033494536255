/* eslint-disable max-len */
import dot from 'dot-object';

const properties = [
  { key: 'summary', label: 'At a glance', placeholder: 'No summary available' },
  // { key: 'demographicBreakdown', label: 'Demographics', placeholder: 'No demographic breakdown available' },
  {
    label: 'Overview',
    items: [
      { key: 'description.overview', label: 'Lifestyle', placeholder: 'No insights available' },
      { key: 'description.projectedPassions', label: 'Core passions', placeholder: 'No passions available' },
      { key: 'description.predictedInterests', label: 'Key tastes', placeholder: 'No predicted interests available' },
    ],
  },
  {
    label: 'Interests',
    items: [
      { key: 'interests.lifestyleBrands', label: 'Brands they love', placeholder: 'No lifestyle brands available' },
      { key: 'interests.frequentPlaces', label: 'Where they gather', placeholder: 'No frequent places available' },
      { key: 'interests.entertainment', label: 'Entertainment choices', placeholder: 'No entertainment data available' },
      { key: 'interests.readingPodcasts', label: 'Media consumption', placeholder: 'No reading or podcast data available' },
      { key: 'interests.music', label: 'Music taste', placeholder: 'No music data available' },
    ],
  },
  {
    label: 'Action Plan',
    items: [
      { key: 'recommendations.actionableInsightsRealEstate', label: 'Real Estate', placeholder: 'No real estate insights available' },
      { key: 'recommendations.actionableInsightsCPG', label: 'CPG', placeholder: 'No CPG insights available' },
      { key: 'recommendations.actionableInsightsEntertainment', label: 'Entertainment', placeholder: 'No entertainment insights available' },
      { key: 'recommendations.actionableInsightsMedia', label: 'Media', placeholder: 'No media insights available' },
      { key: 'recommendations.actionableInsightsRetail', label: 'Retail', placeholder: 'No retail insights available' },
      { key: 'recommendations.actionableInsightsAgencies', label: 'Agencies', placeholder: 'No agencies insights available' },
      {
        key: 'recommendations.idealAmenities', format: (value) => (value?.length ? value.map((v) => `* ${v}`).join('\n') : null), label: 'Amenities Wishlist', placeholder: 'No ideal amenities listed',
      },
    ],
  },
];

const mapProperty = (sectionPrefix, persona) => (property) => {
  const value = dot.pick(property.key, persona);
  const formattedValue = property.format ? property.format(value) : value;
  return `${sectionPrefix} ${property.label}\n${formattedValue || property.placeholder}`;
};

const getPersonaResultMarkdown = (personasResult) => (personasResult || []).map((personaResult, i) => {
  const persona = personaResult?.properties || personaResult;
  return `# Persona ${i + 1}\n${properties.map((property) => {
    if (property.items) {
      return `## ${property.label}\n${property.items.map(mapProperty('###', persona)).join('\n')}`;
    }

    return mapProperty('##', persona)(property);
  }).join('\n')}`;
}).join('\n');

export default getPersonaResultMarkdown;
