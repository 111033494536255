/* eslint-disable max-len */
export default {
  strict: true,
  required: [
    'summary',
    'description',
    'interests',
    'recommendations',
  ],
  additionalProperties: false,
  properties: {
    summary: {
      type: 'string',
      $comment: 'A 3-sentence summary that concisely describes the persona’s core interests, lifestyle, and behavioral tendencies. Grounded in provided input data without much abstraction. Must be data driven.',

    },
    description: {
      type: 'object',
      $comment: 'A well-structured profile outlining **how this persona interacts with their environment, lifestyle, and interests**. Each section should include real-life habits and be **explicitly backed by affinity scores.**',
      properties: {
        overview: {
          type: 'string',
          $comment: '2-3 sentences summarizing their lifestyle and interests based on data-driven themes in a way that feels real. Include interests, experiences, local activities supported by affinity scores. Include small, everyday moments that illustrate how they engage with their interests.',
        },
        projectedPassions: {
          type: 'string',
          $comment: '3-5 interests explicitly derived from data, highlighting motivations behind them and real-world interactions.',
        },
        predictedInterests: {
          type: 'string',
          $comment: "2-3 primary interests and motivations the persona is likely to engage in, based on the input data.  Ensure interests are specific to the input data's context.",
        },
      },
    },
    interests: {
      type: 'object',
      $comment: 'Breakdown of the persona’s **specific preferences**, using entities, tags, and their relevant affinity scores. Avoid vague descriptions—each preference must be **explicitly justified**.',
      properties: {
        lifestyleBrands: {
          type: 'string',
          $comment: '3-5 relevant lifestyle brands, each tied to an **affinity score** and a **clear justification** of why it resonates.',
        },
        frequentPlaces: {
          type: 'string',
          $comment: '3-5 relevant places, each backed by **affinity scores** and an explicit reason why they matter to the persona.',
        },
        entertainment: {
          type: 'string',
          $comment: '3-5 media preferences (movies, TV shows, events, cultural activities), each justified with **affinity scores** and why they resonate.',
        },
        readingPodcasts: {
          type: 'string',
          $comment: '3-5 book or podcast preferences, each **backed by affinity scores** and a clear justification and why they resonate.',
        },
        music: {
          type: 'string',
          $comment: '3-5 musical artists or genres, each justified using **affinity scores** and why they resonate.',
        },
      },
    },
    recommendations: {
      type: 'object',
      $comment: 'Tailored business insights on **how brands, organizations, and businesses** can engage this persona. Each recommendation must be **data-driven and supported by affinity scores**.',
      properties: {
        actionableInsightsRealEstate: {
          type: 'string',
          $comment: 'Explain how real estate offerings can cater to their lifestyle preferences (e.g., urban lofts, nature retreats, social housing).',
        },
        actionableInsightsCPG: {
          type: 'string',
          $comment: 'Identify consumer product opportunities relevant to their interests, such as subscription models, lifestyle-driven packaging, etc.',
        },
        actionableInsightsEntertainment: {
          type: 'string',
          $comment: 'Suggest entertainment formats or content types (e.g., interactive series, short-form content, nostalgic storytelling, etc.) Support with affinity scores.',
        },
        actionableInsightsMedia: {
          type: 'string',
          $comment: 'Define media strategies tailored to their engagement style (e.g., short-form videos, long-form podcasts, community-based content). Support with affinity scores.',
        },
        actionableInsightsRetail: {
          type: 'string',
          $comment: 'Provide retail recommendations that align with their shopping behavior (e.g., experiential retail, online exclusives, customization). Support with affinity scores.',
        },
        actionableInsightsAgencies: {
          type: 'string',
          $comment: 'Outline partnership opportunities and campaigns targeting their behavioral patterns and interests. Support with affinity scores.',
        },
        idealAmenities: {
          type: 'array',
          $comment: 'Descriptions of the ideal amenities for the persona with a clear justification.',
          items: {
            type: 'string',
            $comment: 'List 2-3 specific amenities that would appeal to this persona, with descriptions of why they are relevant and justify.',
          },
        },
      },
    },
  },
};
