import { RadioButtonChecked } from '@mui/icons-material';
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { urnsToDisplayName } from '@qloo/categories';
import { useCategoryPerms, useCategoryPermsWithTag } from '@v1/lib/usePermissions';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { ChevronRight, X } from 'react-feather';

import SubCategoriesChips from '@/components/CategoriesModal/SubCategoriesChip/SubCategoriesChips';
import ModalResetButton from '@/components/ModalResetButton';
import SubCategorySelect from '@/components/SubCategorySelect';

import styles from './CategoriesModal.module.scss';

const CategoriesModal = ({
  category,
  includeTag,
  close,
  onReset,
  onCategoryChange,
  showSubCategorySelect,
  subCategories = {},
  onSubcategoriesChange,
  hideCategorySelector = false,
}) => {
  const headerRef = useRef(null);
  const [bodyHeight, setBodyHeight] = useState('100%');
  const selectedRef = useRef(null);

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [category]);

  useEffect(() => {
    if (headerRef.current) {
      const headerHeight = headerRef.current.offsetHeight;
      setBodyHeight(`calc(100% - ${headerHeight}px)`);
    }
  }, [headerRef?.current?.offsetHeight]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const categories = Object.keys(includeTag ? useCategoryPermsWithTag() : useCategoryPerms());

  const handleChangeSelectedCategory = (urn) => () => {
    if (urn !== category) {
      onCategoryChange(urn);
    }
  };

  const handleDeleteSubCategory = (urn) => () => {
    onSubcategoriesChange(urn, null);
  };

  const handleReset = () => {
    onReset();
    close();
  };

  const hasSubCategories = Object.values(subCategories).length > 0;

  return (
    <div className={styles.container}>
      <div className={clsx(styles.header, !hasSubCategories && styles.headerDefaultHeight)} ref={headerRef}>
        <div className={styles.headerContent}>
          <div className={styles.title}>
            {hideCategorySelector && !!category ? urnsToDisplayName(category) : 'Categories'}
          </div>
          <X className={styles.closeIcon} size={12} onClick={close} />
        </div>
        {hasSubCategories && <SubCategoriesChips subCategories={subCategories} onDelete={handleDeleteSubCategory} />}
      </div>
      <div className={styles.body} style={{ height: bodyHeight }}>
        {!hideCategorySelector && (
          <div className={styles.labels}>
            {categories.map((urn) => (
              <Box
                key={urn}
                className={clsx({
                  [styles.label]: true,
                  [styles.labelActive]: urn === category,
                })}
                ref={urn === category ? selectedRef : null}
                onClick={handleChangeSelectedCategory(urn)}
              >
                <Checkbox
                  checked={urn === category}
                  checkedIcon={<RadioButtonChecked className={styles.checkedIcon} />}
                  icon={<div className={styles.uncheckedCircle} />}
                />
                <span className={styles.labelText}>
                  {urnsToDisplayName(urn)}
                </span>
                {category === urn && Object.keys(subCategories).length > 0 && (
                  <span className={styles.labelIndicator}>
                    {Object.keys(subCategories).length}
                  </span>
                )}
                <ChevronRight className={styles.labelIcon} size={12} />
              </Box>
            ))}
            <Box
              className={clsx({
                [styles.label]: true,
                [styles.labelActive]: category === 'urn:tag',
              })}
              ref={category === 'urn:tag' ? selectedRef : null}
              onClick={handleChangeSelectedCategory('urn:tag')}
            >
              <Checkbox
                checked={category === 'urn:tag'}
                checkedIcon={<RadioButtonChecked className={styles.checkedIcon} />}
                icon={<div className={styles.uncheckedCircle} />}
              />
              <span className={styles.labelText}>
                Tag
              </span>
            </Box>
            {category && <ModalResetButton onReset={handleReset} />}
          </div>
        )}
        {!!category && category !== 'urn:tag' && showSubCategorySelect && (
          <SubCategorySelect
            category={category}
            selectedSubCategories={subCategories}
            onChange={onSubcategoriesChange}
            hideCategorySelector={hideCategorySelector}
          />
        )}
      </div>
    </div>
  );
};

export default CategoriesModal;
