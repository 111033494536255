import Box from '@mui/material/Box';
import React from 'react';

// eslint-disable-next-line import/no-cycle
import { useStateWithDebounce } from '@/hooks';

import Input from '../Input';
import styles from './RangeInputs.module.scss';

const RangeInputs = ({
  onChange, min = 0, max = undefined, value,
}) => {
  const [values, setValues] = useStateWithDebounce({
    baseValue: value,
    onChange,
  });

  const [valueMin, valueMax] = values || [undefined, undefined];

  const onChangeMin = (nextMin) => {
    const effectiveMin = min ? Math.max(nextMin, min) : nextMin;
    const updatedValues = [effectiveMin, valueMax];
    setValues(updatedValues);
  };

  const onChangeMax = (nextMax) => {
    const effectiveMax = max ? Math.min(nextMax, max) : nextMax;
    const updatedValues = [valueMin, effectiveMax];
    setValues(updatedValues);
  };

  return (
    <Box className={styles.container}>
      <span>From</span>
      <Input type="number" min={min} onChange={onChangeMin} value={valueMin} />
      <span>To</span>
      <Input type="number" max={max} onChange={onChangeMax} value={valueMax} />
    </Box>
  );
};

export default RangeInputs;
