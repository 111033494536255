import { geojsonToWKT, wktToGeoJSON } from '@terraformer/wkt';
import geoJsonAreaSizeMeters from '@turf/area';
import centroid from '@turf/centroid';
import { toHumanString } from 'human-readable-numbers';

export const featureToCollection = (geometry) => (geometry?.type === 'FeatureCollection' ? geometry : ({
  type: 'FeatureCollection',
  features: geometry ? [{
    type: 'Feature',
    properties: {},
    geometry,
  }] : [],
}));

export const featuresToCollection = (features) => (features?.type === 'FeatureCollection' ? features : ({
  type: 'FeatureCollection',
  features: features || [],
}));

// Simpler, because we're using lon,lat (mapbox) and lat,lon (us)
export const llre = /^[-+]?\d+\.\d+,\s*[-+]?\d+\.\d+$/;

// latLon to lonLat
export const reverseLL = (str) => str.split(',').reverse().join(',');

export const getLLCenter = (geoJson) => centroid(geoJson)?.geometry?.coordinates?.join(',');

export const geoJsonAreaSizeMiles = (geoJson) => {
  try {
    const meters = geoJsonAreaSizeMeters(geoJson);
    if (!meters) return null;
    const miles = meters * 0.00000038610215855;
    return miles;
  } catch (e) {
    return null;
  }
};

const UNITS = {
  miles: 'miles',
  meters: 'meters',
};

export const geoJsonAreaSize = (geoJson, { unit = UNITS.miles } = {}) => {
  try {
    let size = unit === UNITS.miles ? geoJsonAreaSizeMiles(geoJson) : geoJsonAreaSizeMeters(geoJson);

    let unitName;
    if (unit === UNITS.miles) {
      unitName = 'mi²';
    } else {
      // eslint-disable-next-line no-lonely-if
      if (size >= 1000) {
        size /= 1000;
        unitName = 'km²';
      } else {
        unitName = 'm²';
      }
    }

    return `${size < 1 ? 'less than 1' : toHumanString(size)} ${unitName} region`;
  } catch (e) {
    return null;
  }
};

export const geoJsonToWkt = (geojson) => {
  try {
    return geojsonToWKT(geojson);
  } catch (e) {
    return null;
  }
};

export const WktToGeoJson = (wktString) => {
  // TODO: kludge, not sure where wktString is being array'd as of yet, maybe in QlooURL parser?
  /* eslint-disable react/destructuring-assignment */
  const wkta = Array.isArray(wktString) ? wktString.join(',') : wktString;
  try {
    return wktToGeoJSON(wkta);
  } catch (e) {
    return null;
  }
};

export const LLToWktPoint = ({ lon, lat }) => `POINT(${lon} ${lat})`;

export const radiusHumanizer = (meters) => {
  if (meters < 1000) {
    return `${meters} m radius around`;
  }

  return `${toHumanString(meters / 1000)} km radius around`;
};

export const locationHumanizer = (location) => {
  if (!location) return null;

  if (!location.label) return null;

  if (location.value.startsWith('POINT') && location.radius) {
    return `${radiusHumanizer(location.radius)} ${location.label}`;
  }

  return location.label;
};

export const createWKTFromBounds = (bounds) => {
  const west = bounds.getWest();
  const south = bounds.getSouth();
  const east = bounds.getEast();
  const north = bounds.getNorth();

  // WKT format: POLYGON ((west south, east south, east north, west north, west south))
  return `POLYGON((${west} ${south}, ${east} ${south}, ${east} ${north}, ${west} ${north}, ${west} ${south}))`;
};

export const getCentroid = (locationValue) => {
  if (locationValue.startsWith('POINT')) {
    const [lon, lat] = locationValue.match(/-?\d+\.\d+/g);
    return `"${Number(lon).toFixed(5)},${Number(lat).toFixed(5)}~"`;
  }

  if (locationValue.startsWith('POLYGON')) {
    const point = centroid(WktToGeoJson(locationValue));
    const [lon, lat] = point.geometry.coordinates;
    return `"${Number(lon).toFixed(5)},${Number(lat).toFixed(5)}~"`;
  }

  return null;
};
