import {
  Box, Skeleton,
} from '@mui/material';
import React from 'react';

const DashboardShareWithSkeleton = () => {
  const rows = Array.from(new Array(3));

  return (
    <Box display="flex" flexDirection="column" width="100%" gap={2} pl={2} pr={2} mb={2}>
      <Skeleton variant="rectangular" width={150} height={25} />
      {rows.map((el, i) => (
        <Box display="flex" alignItems="center" justifyContent="space-between" key={i}>
          <Box display="flex" alignItems="center" gap={1}>
            <Skeleton variant="circular" width={35} height={35} />
            <Box>
              <Skeleton variant="rectangular" width={200} height={15} sx={{ marginBottom: 0.5 }} />
              <Skeleton variant="rectangular" width={200} height={15} />
            </Box>
          </Box>
          <Skeleton variant="rectangular" width={24} height={24} />
        </Box>
      ))}
    </Box>
  );
};

export default DashboardShareWithSkeleton;
