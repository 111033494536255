import clsx from 'clsx';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { interestTypes } from '@/constants';
import { useEntityBatchedRequestsContext, useTagBatchedRequestsContext } from '@/contexts';
import { fetchEntityStart } from '@/store/slices/entitiesSlice';
import { fetchTagStart } from '@/store/slices/tagsSlice';

// TODO: Looks like this is used for entity and tag components. Rename to OmnisearchItemText or something similar.
const EntityText = ({ className, item, index }) => {
  const isTag = item.type === interestTypes.tag;

  const entityBatchedRequest = useEntityBatchedRequestsContext();
  const tagBatchedRequest = useTagBatchedRequestsContext();

  const dispatch = useDispatch();

  const entity = useSelector((state) => state.entities.byId[item.id]);
  const tag = useSelector((state) => state.tags.byId[item.id]);

  const fetchStart = isTag ? fetchTagStart : fetchEntityStart;
  const queueArg = isTag ? tagBatchedRequest.queueArg : entityBatchedRequest.queueArg;

  // Get label from item or store
  const label = useMemo(() => {
    if (item.name) return item.name;

    return isTag ? tag?.name : entity?.name;
  }, [item, isTag, tag, entity]);

  // Fetch entity/tag if not in store
  const fetchIfNeeded = () => {
    if (!label) {
      dispatch(fetchStart(item.id));
      queueArg(item.id);
    }
  };

  useEffect(() => {
    fetchIfNeeded();
  }, []);

  return (
    <>
      {typeof index === 'number' && index > 0 ? <span>{',\u00A0'}</span> : null}
      <span className={clsx({ [className]: className })}>{label || `${String(item.id).slice(0, 11)}...`}</span>
    </>
  );
};

export default EntityText;
