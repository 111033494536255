import { Alert as MuiAlert } from '@mui/material';
import React from 'react';

const Alert = ({ message, severity, ...props }) => {
  return (
    <MuiAlert severity={severity} {...props}>{message}</MuiAlert>
  );
};

export default Alert;
