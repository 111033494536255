import Menu from '@mui/material/Menu';
import clsx from 'clsx';
import { nestedMenuItemsFromObject } from 'mui-nested-menu';
import React, { useMemo, useState } from 'react';
import { Zap } from 'react-feather';
import { useSelector } from 'react-redux';

import Button from '@/components/Button';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import panelTypes, { panelTypeLabels } from '@/constants/panelTypes';
import getIsQlooUser from '@/utils/getIsQlooUser';
import tooltips from '@/utils/tooltips';

import { useDashboardActionsContext } from '../DashboardActionsProvider';
import styles from './DashboardToolbar.module.scss';

const getMenuItemsData = ({ dashboardActions, isQlooUser }) => {
  const menuItems = [
    {
      id: panelTypes.table,
      label: panelTypeLabels[panelTypes.table],
      callback: () => {
        dashboardActions.addPanel();
      },
    },
    {
      id: panelTypes.heatmap,
      label: panelTypeLabels[panelTypes.heatmap],
      callback: () => {
        dashboardActions.addHeatmapPanel();
      },
    },
    {
      id: panelTypes.explore,
      label: panelTypeLabels[panelTypes.explore],
      callback: () => {
        dashboardActions.addExplorePanel();
      },
    },
    {
      id: panelTypes.locationExplorer,
      label: panelTypeLabels[panelTypes.locationExplorer],
      callback: () => {
        dashboardActions.addLocationExplorerPanel();
      },
    },
    {
      id: panelTypes.freeformText,
      label: panelTypeLabels[panelTypes.freeformText],
      callback: () => {
        dashboardActions.addFreeformTextPanel();
      },
    },
  ];

  return isQlooUser ? menuItems : menuItems.filter((item) => item.id !== panelTypes.freeformText);
};

const NewInsightPopover = () => {
  const dashboardActions = useDashboardActionsContext();
  const { identity } = useSelector((state) => state.app);

  const menuItemsData = useMemo(() => {
    const isQlooUser = Boolean(getIsQlooUser(identity));
    return getMenuItemsData({ dashboardActions, isQlooUser });
  }, [dashboardActions, identity]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const menuItems = nestedMenuItemsFromObject({
    handleClose,
    isOpen: open,
    menuItemsData,
  });

  return (
    <div className={styles.newInsightButtonContainer}>
      <Button onClick={handleClick} variant="text">
        <div className={clsx({
          [styles.newInsightButton]: true,
          [styles.newInsightButtonOpen]: open,
        })}
        >
          <div className={styles.newInsightButtonIcon}>
            <Zap color="#000000" size={12} />
          </div>
          <TooltipInfo title={tooltips.dashboard.newInsights}>
            <span className={styles.newInsightButtonText}>
              New Insight
            </span>
          </TooltipInfo>
        </div>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems}
      </Menu>
    </div>
  );
};

export default NewInsightPopover;
