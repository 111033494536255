import 'react-loading-skeleton/dist/skeleton.css';

import clsx from 'clsx';
import RLSkeleton from 'react-loading-skeleton';

import styles from './Skeleton.module.scss';

const Skeleton = ({ disabled, className, ...props }) => {
  return (
    <RLSkeleton {...props} enableAnimation={!disabled} className={clsx(styles.skeleton, className)} />
  );
};

export default Skeleton;
