import {
  DEFAULT_PANEL_HEIGHT, DEFAULT_PANEL_WIDTH, MIN_PANEL_HEIGHT,
  NUMBER_OF_COLUMNS,
} from './constants';

const getNextLayoutItem = (panelId, layout) => {
  const w = DEFAULT_PANEL_WIDTH;
  const h = DEFAULT_PANEL_HEIGHT;
  const isGhost = panelId === 'add-insight-panel';

  if (!layout.length) {
    return {
      i: panelId,
      x: 0,
      y: 0,
      w,
      h,
      minH: MIN_PANEL_HEIGHT,
      isDraggable: !isGhost,
      isResizable: !isGhost,
    };
  }

  const lastItem = layout.reduce((acc, item) => {
    if (item.y > acc.y) {
      return item;
    }

    if (item.y === acc.y && item.x > acc.x) {
      return item;
    }

    return acc;
  }, layout[0]);

  const x = lastItem.x + lastItem.w + w <= NUMBER_OF_COLUMNS ? lastItem.x + lastItem.w : 0;
  const y = x === 0 ? lastItem.y + lastItem.h + 1 : lastItem.y;

  return {
    i: panelId,
    x,
    y,
    w,
    h,
    minH: MIN_PANEL_HEIGHT,
    isDraggable: !isGhost,
    isResizable: !isGhost,
  };
};

export default getNextLayoutItem;
