import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BuildIcon from '@mui/icons-material/Build';
import GridViewIcon from '@mui/icons-material/GridView';
import JoinRightIcon from '@mui/icons-material/JoinRight';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import tooltips from '@/utils/tooltips';

export const TABS = {
  dashboards: {
    label: 'Dashboards',
    to: '/dashboards',
    icon: GridViewIcon,
    tooltip: tooltips.navigation.dashboards,
  },

  requests: {
    label: 'Requests',
    to: '/requests',
    alias: '/recs',
    icon: BuildIcon,
    tooltip: tooltips.navigation.requests,
  },

  compare: {
    label: 'Compare',
    to: '/compare',
    icon: JoinRightIcon,
    tooltip: tooltips.navigation.compare,
  },

  shareables: {
    label: 'Shareables',
    to: '/shareables',
    icon: MenuBookIcon,
    tooltip: tooltips.navigation.sharables,
  },

  admin: {
    label: 'Admin',
    to: '/admin',
    icon: AdminPanelSettingsIcon,
  },

};

export const USABLE_PAGES = Object.keys(TABS);
export const SEARCH_ENABLED = !import.meta.env.VITE_LAYOUT_SIMPLE;
export const ENTITY_VIEW_ENABLED = !import.meta.env.VITE_LAYOUT_SIMPLE;
