import memoize from 'fast-memoize';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useDashboardActionsContext } from '@/screens/Dashboard/DashboardActionsProvider';
import { useGetTagsQuery } from '@/store/apis/tagsApi';
import CANONICAL_TAG from '@/utils/canonicalTag';

import CategoriesDataList, { CategoryDataView } from './CategoriesDataList';
import { HIGHLIGHT_DATA } from './constants';
import styles from './PanelBodyExplore.module.scss';
import SelectedCategoryContent from './SelectedCategory/SelectedCategoryContent';

const PanelBodyExplore = ({
  panel,
  setHasMoreExplorePages,
  isLocationExplorer = false,
}) => {
  const dashboardActions = useDashboardActionsContext();
  const { panelId, settings: panelSettings } = panel;

  const handleCategorySelected = (nextSelectedCategory) => () => {
    const nextSettings = {
      ...panelSettings,
      selectedCategory: nextSelectedCategory,
    };

    dashboardActions.changePanelSettings({
      panelId,
      nextSettings,
    });
  };

  const { isExploreConciseView } = useSelector((state) => state.dashboards);
  const { selectedCategory } = panelSettings;
  const [subCategories, setSubCategories] = useState();
  const { initialSelectedCategory } = panelSettings;

  // TODO: Refactor this
  const validatedSelectedCategory = useMemo(() => {
    setSubCategories({});
    return HIGHLIGHT_DATA.reduce((result, data) => {
      const matchingTag = data.tags.find((tag) => tag.tagValue === selectedCategory);
      if (matchingTag) {
        setSubCategories({ [selectedCategory]: 'include' });
        return data.category;
      }
      return result;
    }, selectedCategory);
  }, [selectedCategory]);

  const filters = useMemo(() => ({
    query: '',
    parentType: validatedSelectedCategory,
    take: 500,
    tags: CANONICAL_TAG,
  }), [validatedSelectedCategory]);

  const { data: tagsResult } = useGetTagsQuery(filters);

  const handleSubCategoryChange = useCallback(
    (keyName, newValue) => {
      if (keyName === selectedCategory) return;
      setSubCategories((prev) => (newValue === null
        ? Object.fromEntries(Object.entries(prev).filter(([key]) => key !== keyName))
        : { ...prev, [keyName]: newValue ? 'include' : 'exclude' }));
    },
    [selectedCategory],
  );

  const handleSubcategoriesReset = () => {
    setSubCategories({});
  };

  if (selectedCategory) {
    return (
      <div className={styles.container}>
        <SelectedCategoryContent
          panel={panel}
          selectedCategory={validatedSelectedCategory}
          fixedSubcategory={selectedCategory}
          selectedSubCategories={subCategories}
          setHasMoreExplorePages={setHasMoreExplorePages}
          categorySubCategories={tagsResult}
          onCategorySelected={handleCategorySelected}
          onSubCategoryChange={handleSubCategoryChange}
          onSubcategoriesReset={handleSubcategoriesReset}
        />
      </div>
    );
  }

  if (initialSelectedCategory) {
    return (
      <CategoryDataView
        category={initialSelectedCategory}
        onSelect={handleCategorySelected}
        conciseView={isExploreConciseView}
      />
    );
  }

  return (
    <div className={styles.container}>
      <CategoriesDataList
        panel={panel}
        onSelect={handleCategorySelected}
        visible={!selectedCategory}
        isLocationExplorer={isLocationExplorer}
      />
    </div>
  );
};

export default memoize(PanelBodyExplore);
