export const DashboardRole = {
  FEATURE: 'feature.dashboard',
  VIEW: 'feature.dashboard.view',
  CREATE: 'feature.dashboard.create',
  UPDATE: 'feature.dashboard.update',
  DELETE: 'feature.dashboard.delete',
};

const dashboard = {
  FEATURE: { message: "You don't have access to dashboard's feature.", role: DashboardRole.FEATURE, status: 423 },
  VIEW: { message: "You don't have permission to view dashboards.", role: DashboardRole.VIEW },
  CREATE: { message: "You don't have permission to create dashboards.", role: DashboardRole.CREATE },
  UPDATE: { message: "You don't have permission to update dashboards.", role: DashboardRole.UPDATE },
  DELETE: { message: "You don't have permission to delete dashboards.", role: DashboardRole.DELETE },
};

export function PermissionError(permission) {
  const error = new Error(permission?.message);
  error.status = permission?.status;
  return error;
}

export default dashboard;
