import { useDispatch, useSelector } from 'react-redux';

import { clearOwnership, setOwnership } from '@/store/slices/appSlice';

import useLiveState from '../screens/Dashboard/hooks/useLiveState';

const useOwnership = (key) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.app);
  const value = useSelector((state) => state.app.ownership[key]);

  const { emit } = useLiveState({
    liveStateDataId: key,
    onChange: ({ userId }) => {
      dispatch(setOwnership({ key, userId }));
    },
  });

  const set = () => {
    const { userId } = user;
    emit({ userId });
    dispatch(setOwnership({ key, userId }));
  };

  const clear = () => {
    emit({ userId: null });
    dispatch(clearOwnership({ key }));
  };

  return {
    value,
    set,
    clear,
  };
};

export default useOwnership;
