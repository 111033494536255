export const INITIAL_POSITION = 50000;
export const POSITION_STEP = 1000;
export const MIN_DIFFERENCE = 10;

export function getNewTabPositionForCreation(positions) {
  if (!positions || positions.length === 0) {
    return INITIAL_POSITION;
  }
  return positions[0] - POSITION_STEP;
}

export function getNewTabPositionForMove(prevPosition, nextPosition) {
  if (prevPosition !== null && nextPosition !== null) {
    return Math.floor((prevPosition + nextPosition) / 2);
  } if (prevPosition !== null) {
    return prevPosition + POSITION_STEP;
  } if (nextPosition !== null) {
    return Math.floor(nextPosition / 2);
  }
  return INITIAL_POSITION;
}

export function shouldRebalance(positions) {
  const allDefault = positions.every((pos) => pos === INITIAL_POSITION);
  if (allDefault) return false;

  for (let i = 0; i < positions.length - 1; i += 1) {
    if (Math.abs(positions[i + 1] - positions[i]) < MIN_DIFFERENCE) {
      return true;
    }
  }
  return false;
}

export function rebalancePositions(count) {
  const positions = [];
  const step = Math.floor(INITIAL_POSITION / (count + 1));
  let pos = INITIAL_POSITION;
  for (let i = 0; i < count; i += 1) {
    positions.push(pos);
    pos -= step;
  }
  return positions;
}
