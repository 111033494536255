import useGPT from '@v1/lib/useGPT';

import explainRecsGPTPrompt from '@/utils/explainRecsGPTPrompt';

const useGPTExplainRecs = ({
  category, tags, entities, showMini,
}) => {
  const prompt = explainRecsGPTPrompt({
    inputEntities: entities,
    recsEntities: tags,
    recsCategoryUrn: category,
    showMini,
  });
  const { result: gptResult, error: gptError, isLoading: gptLoading } = useGPT(prompt, {
    key: `gpt-v2-${prompt}`,
  });

  return {
    isLoading: gptLoading,
    error: gptError,
    result: gptLoading || gptError ? null : gptResult,
  };
};

export default useGPTExplainRecs;
