import Button from '@mui/material/Button';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Input from '@/components/Input';
import { useCreateDashboardMutation } from '@/store/apis/dashboardsApi';

import getTitle from '../helpers/getTitle';
import styles from './DashboardTabs.module.scss';

const MIN_NUMBER_OF_ITEMS_TO_SHOW_INPUT = 5;

const getSearchedDashboards = (search, dashboards) => {
  if (search && dashboards.length >= MIN_NUMBER_OF_ITEMS_TO_SHOW_INPUT) {
    const searchLowered = search.toLowerCase();
    return dashboards.filter((dashboard) => {
      const title = getTitle(dashboard);
      return title.toLowerCase().indexOf(searchLowered) > -1;
    });
  }

  return dashboards;
};

const DashboardTabsAddButtonPanel = ({ close }) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [createDashboard, { isLoading }] = useCreateDashboardMutation();

  const getHandleAdd = (id) => () => {
    navigate(`/dashboards/view/${id}`);
    close();
  };

  const handleCreate = async () => {
    const { data } = await createDashboard();
    navigate(`/dashboards/view/${data.id}`);
    close();
  };

  const { items } = useSelector((state) => state.dashboards);
  const { dashboardIds } = useSelector((state) => state.dashboardTabs);

  const availableDashboards = useMemo(() => {
    const dashboardIdsBitmap = dashboardIds.reduce((obj, dashboardId) => ({ ...obj, [dashboardId]: 1 }), {});
    return Object.values(items).filter((dashboard) => dashboard.id && !dashboardIdsBitmap[dashboard.id]).sort((a, b) => {
      const aTitle = getTitle(a);
      const bTitle = getTitle(b);
      return aTitle.localeCompare(bTitle);
    });
  }, [dashboardIds, items]);
  const searchedDashboards = useMemo(
    () => getSearchedDashboards(search, availableDashboards),
    [availableDashboards, search],
  );

  return (
    <div className={styles.addButtonPanel}>
      {availableDashboards.length
        ? (
          <>
            {availableDashboards.length >= MIN_NUMBER_OF_ITEMS_TO_SHOW_INPUT
              ? (
                <div className={styles.addButtonPanelInputContainer}>
                  <Input
                    className={styles.addButtonPanelInput}
                    onChange={setSearch}
                    placeholder="Search existing dashboards"
                    type="text"
                    value={search}
                  />
                </div>
              ) : null}
            <div className={styles.addButtonPanelOptions}>
              {searchedDashboards.length ? searchedDashboards
                .map((dashboard) => (
                  <Button
                    key={dashboard.id}
                    onClick={getHandleAdd(dashboard.id)}
                    size="small"
                    color="secondary"
                    align="left"
                    fullWidth
                  >
                    {getTitle(dashboard)}
                  </Button>
                )) : <div className={styles.addButtonPanelOptionsPlaceholder}>No dashboards found</div>}
            </div>
          </>
        )
        : null}
      <div className={styles.addButtonPanelFooter}>
        <Button
          className={styles.addButtonPanelFooterButton}
          loading={isLoading}
          loadingPosition="start"
          onClick={handleCreate}
          size="small"
          variant="contained"
        >
          Create New Dashboard
        </Button>
      </div>
    </div>
  );
};

export default DashboardTabsAddButtonPanel;
