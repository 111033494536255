import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material';
import { bindDialog, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useState } from 'react';

import { useDashboardActionsContext } from '../../DashboardActionsProvider';

const RenameDialog = ({ panelId, panelTitle = '', popupState }) => {
  const dashboardActions = useDashboardActionsContext();
  const [title, setTitle] = useState(panelTitle);

  const handleChange = (e) => {
    // stopPropagation was added to prevent accidental hotkey triggers
    e.stopPropagation();
    setTitle(e.currentTarget.value);
  };

  const handleRename = async () => {
    await dashboardActions.changePanelTitle({ panelId, nextTitle: title });
    popupState.close();
  };

  return (
    <Dialog {...bindDialog(popupState)} fullWidth maxWidth="sm">
      <DialogTitle>Rename Panel</DialogTitle>
      <DialogContent sx={{ paddingTop: '14px !important' }}>
        <TextField
          required
          fullWidth
          autoFocus
          error={!title}
          label="Name"
          value={title}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={popupState.close}>Cancel</Button>
        <Button
          onClick={handleRename}
          disabled={!title}
          variant="contained"
          loading={dashboardActions.isUpdatingPanels}
          loadingPosition="start"
          startIcon={<EditIcon />}
        >
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MenuItemRename = ({
  panelId, panelTitle, disabled, ...props
}) => {
  const popupState = usePopupState({ variant: 'dialog', popupId: 'renameDialog' });

  return (
    <>
      <MenuItem {...bindTrigger(popupState)} disabled={disabled} {...props}>
        <ListItemIcon><EditIcon /></ListItemIcon>
        <ListItemText>Rename Panel</ListItemText>
      </MenuItem>
      <RenameDialog
        panelId={panelId}
        panelTitle={panelTitle}
        popupState={popupState}
      />
    </>
  );
};

export default MenuItemRename;
