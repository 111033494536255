import { Button as MuiButton } from '@mui/base/Button';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import styles from './Button.module.scss';

// TODO: Active
const Button = ({
  children,
  className = undefined,
  variant = undefined,
  color = undefined,
  isLoading = undefined,
  size = undefined,
  noPadding = undefined,
  disabled = false,
  bold = false,
  ...rest
}) => (
  <MuiButton
    disabled={disabled}
    className={clsx({
      [styles.button]: true,
      [styles.primary]: !color || color === 'primary',
      [styles.secondary]: color === 'secondary',
      [styles.accent]: color === 'accent',
      [styles.success]: color === 'success',
      [styles.danger]: color === 'danger',
      [styles.black]: color === 'black',
      [styles.transparent]: color === 'transparent',
      [styles.contained]: !variant || variant === 'contained',
      [styles.outlined]: variant === 'outlined',
      [styles.text]: variant === 'text',
      [styles.link]: variant === 'link',
      [styles.noPadding]: !!noPadding,
      [styles.small]: size === 'small',
      [styles.disabled]: disabled,
      [styles.bold]: bold,
      [className]: className,
    })}
    {...rest}
  >
    {!!isLoading && (
      <>
        <CircularProgress size={16} color="inherit" />
        &nbsp;
      </>
    )}
    {children}
  </MuiButton>
);
export default Button;
