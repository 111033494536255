import { ReactComponent as Logo } from '@v1/components/LogoSm.svg';
import clsx from 'clsx';
import React from 'react';

import styles from './LogoLoading.module.scss';

const LogoLoading = ({
  className, size, shouldPulse, shouldShowError,
}) => (
  <div className={clsx(styles.logoContainer, className)}>
    <Logo className={clsx(
      styles.logoSvg,
      {
        [styles.small]: size === 'small',
        [styles.medium]: size === 'medium',
        [styles.large]: size === 'large',
        [styles.logoSvgPulse]: shouldPulse,
        [styles.logoSvgStatic]: !shouldPulse,
      },
    )}
    />
    {shouldShowError
        && <div className={styles.logoText}>Error loading data</div>}
  </div>
);

export default LogoLoading;
