import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import clsx from 'clsx';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Tooltip from '@/components/Tooltip';
import QueryBuilder from '@/screens/Dashboard/QueryBuilder';
import getIsQlooUser from '@/utils/getIsQlooUser';

import styles from './CreativesModal.module.scss';
import CreativesModalMissingParams from './CreativesModalMissingParams';
import getMissingRequiredParams from './getMissingRequiredParams';
import { colorByTemplateStatus, statuses, TEMPLATE_STATUSES } from './statuses';
import templatesByKey from './templates';

const UI_SCHEMA_DEFAULT = {
  'ui:submitButtonOptions': {
    norender: true,
  },
};

const SelectWithPlaceholder = ({
  placeholder,
  value,
  onChange,
  children,
  sx,
  ...props
}) => (
  <Select
    displayEmpty
    value={value || ''}
    onChange={onChange}
    sx={sx}
    {...props}
  >
    <MenuItem value="" disabled>{placeholder}</MenuItem>
    {children}
  </Select>
);

const CreativesModalStepOne = ({
  availableCreatives,
  baseParams,
  formRef,
  selectedTemplateKeyState,
  userDataState,
}) => {
  const [userData, setUserData] = userDataState;
  const [selectedTemplateKey, setSelectedTemplateKey] = selectedTemplateKeyState;

  const template = templatesByKey[selectedTemplateKey];

  const handleChange = ({ formData }) => {
    setUserData(formData);
  };

  // TODO this is a hack to make sure the form data is merged with the default form data
  useEffect(() => {
    if (selectedTemplateKey && template?.defaultFormData) {
      setUserData({
        ...userData,
        ...template.defaultFormData,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateKey]);

  const missingRequiredParams = useMemo(
    () => getMissingRequiredParams(baseParams, template?.required || []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [baseParams, selectedTemplateKey],
  );

  const { identity } = useSelector((state) => state.app);

  const isQlooUser = useMemo(() => Boolean(getIsQlooUser(identity)), [identity]);

  return (
    <div className={styles.contentContainer}>
      <Typography variant="h2" className={styles.baseParamsHeader} />
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <SelectWithPlaceholder
          placeholder="Select an action"
          onChange={(e) => setSelectedTemplateKey(e.target.value)}
          value={selectedTemplateKey}
          sx={{
            fontSize: '1.5rem',
          }}
        >
          {availableCreatives.map((key) => {
            const templateItem = templatesByKey[key];
            const status = statuses[key] || TEMPLATE_STATUSES.EXPERIMENTAL;
            const color = colorByTemplateStatus[status];
            const secondary = templateItem.description || templateItem.credit;
            return (
              <MenuItem className={styles[`selectItem${status}`]} key={key} value={key}>
                <ListItemText
                  primary={(
                    <div className={styles.selectItemPrimary}>
                      {isQlooUser ? (
                        <div className={styles.selectDotContainer}>
                          <Tooltip title={status}>
                            <div
                              className={styles.selectDot}
                              style={{
                                backgroundColor: color,
                              }}
                            />
                          </Tooltip>
                        </div>
                      ) : null}
                      <span>{`${templateItem.name}${templateItem.credit ? ` by ${templateItem.credit}` : ''}`}</span>
                    </div>
                  )}
                  secondary={secondary ? <div style={{ marginLeft: isQlooUser ? '26px' : '0' }}>{secondary}</div> : null}
                />
              </MenuItem>
            );
          })}
        </SelectWithPlaceholder>
      </FormControl>

      {!selectedTemplateKey || template?.hideBaseQuerySummary ? null : (
        <>
          {missingRequiredParams.length
            ? <CreativesModalMissingParams missingRequiredParams={missingRequiredParams} />
            : null}
          <div className={styles.baseParams}>
            <Typography variant="h2" className={styles.baseParamsHeader}>
              Using this audience
            </Typography>

            <QueryBuilder params={baseParams} onOpen={() => { }} veltLocation="">
              {({ DemographicsComponent, InterestsComponent, LocationComponent }) => (
                <div className={styles.baseParamsInner}>
                  <DemographicsComponent
                    color="orange"
                    className={clsx([styles.baseParamsPicker, styles.baseParamsPickerLightOrange])}
                  />
                  &nbsp;
                  <div>from</div>
                  &nbsp;
                  <LocationComponent
                    color="purple"
                    className={clsx([styles.baseParamsPicker, styles.baseParamsPickerLightPurple])}
                  />
                  &nbsp;
                  <div>that like{baseParams?.gender ? '' : 's'}</div>
                  &nbsp;
                  <InterestsComponent
                    color="blue"
                    className={clsx([styles.baseParamsPicker, styles.baseParamsPickerLightBlue])}
                  />
                </div>
              )}
            </QueryBuilder>

          </div>
        </>
      )}
      {template?.form ? (
        <div className={styles.formContainer}>
          <Form
            formData={userData}
            uiSchema={{
              ...UI_SCHEMA_DEFAULT,
              ...(template?.formUiSchema || {}),
            }}
            onChange={handleChange}
            ref={formRef}
            schema={template.form}
            validator={validator}
            disabled={!!missingRequiredParams.length}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CreativesModalStepOne;
