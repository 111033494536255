import RadarIcon from '@mui/icons-material/Radar';
import { Box } from '@mui/system';

import Slider from '../Slider';
import styles from './MapDrawShape.module.scss';

const RadiusSelector = ({ radius, onChange }) => {
  return (
    <Box display="flex" alignItems="center" gap="8px" padding="0 10px" minWidth={250}>
      <RadarIcon fontSize="small" />
      <span>Radius:</span>
      <Slider
        min={1}
        max={800000}
        value={radius}
        onChange={onChange}
        step={1}
        debounceDelay={0}
        withInput
        size="small"
        inputProps={{
          variant: 'standard',
          disableUnderline: true,
          className: styles.radiusInput,
        }}
      />
    </Box>
  );
};

export default RadiusSelector;
