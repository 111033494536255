import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { api } from '@/constants';
import { setIdentity } from '@/store/slices/appSlice';
import { mergeIdentities } from '@/store/slices/identitiesSlice';
import { getHash } from '@/utils/format';

const IDENTITY_TAG = 'identity';

export const identityApi = createApi({
  reducerPath: 'identityApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${api.hostname}/corporateapi/identity` }),
  mode: 'cors',
  prepareHeaders: (headers) => {
    headers.set('Content-Type', 'application/json');
    return headers;
  },
  tagTypes: [IDENTITY_TAG],
  endpoints: (builder) => ({
    getIdentity: builder.query({
      query: ({ supertokensExternalId }) => ({
        url: '/identityGetV2',
        params: {
          supertokensExternalId,
        },
      }),
      providesTags: (result) => {
        const tags = [{ type: IDENTITY_TAG, id: 'LIST' }];
        if (result) {
          tags.push({ type: IDENTITY_TAG, id: result.identityId });
        }
        return tags;
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setIdentity(data));
        } catch (error) {
          console.error('Failed to fetch dashboard details:', error);
        }
      },
    }),
    getOrganizations: builder.query({
      query: () => '/organizationsGetV2',
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(mergeIdentities(data));
      },
      providesTags: (result) => {
        const tags = [{ type: IDENTITY_TAG, id: 'LIST' }];

        if (result) {
          result.forEach((org) => {
            if (org.id) {
              tags.push({ type: IDENTITY_TAG, id: org.id });
            }
          });
        }
        return tags;
      },
    }),
    organizationUpsert: builder.mutation({
      query: (organizationPersonnel) => ({
        url: '/organizationUpsertV2',
        method: 'PUT',
        body: organizationPersonnel,
      }),
      // eslint-disable-next-line max-len
      invalidatesTags: (result, error, { identityId }) => [{ type: IDENTITY_TAG, identityId }],
    }),
    teamUpsert: builder.mutation({
      query: (team) => ({
        url: '/teamUpsertV2',
        method: 'PUT',
        body: team,
      }),
      // eslint-disable-next-line max-len
      invalidatesTags: (result, error, { identityId }) => [{ type: IDENTITY_TAG, identityId }],
    }),
    organizationPersonnelUpsert: builder.mutation({
      query: (organizationPersonnel) => ({
        url: '/organizationPersonnelUpsertV2',
        method: 'PUT',
        body: organizationPersonnel,
      }),
      // eslint-disable-next-line max-len
      invalidatesTags: (result, error, { identityId }) => [{ type: IDENTITY_TAG, identityId }],
    }),
    personnelDisableLogin: builder.mutation({
      query: (personnel) => ({
        url: '/personnelDisableLogin',
        method: 'PUT',
        body: personnel,
      }),
      // eslint-disable-next-line max-len
      invalidatesTags: (result, error, { identityId }) => [{ type: IDENTITY_TAG, identityId }],
    }),
    getOrganizationPersonnelDashboard: builder.query({
      query: ({ organizationId }) => ({
        url: '/organizationPersonnelGet',
        params: {
          organizationId,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(mergeIdentities(data));
      },
      providesTags: (result) => {
        const tags = [{ type: IDENTITY_TAG, id: 'LIST' }];
        if (result) {
          result.forEach((org) => {
            if (org.identityId) {
              tags.push({ type: IDENTITY_TAG, id: org.identityId });
            }
          });
        }
        return tags;
      },
    }),
    identityGet: builder.query({
      query: ({ supertokensExternalId }) => {
        if (!supertokensExternalId) {
          throw new Error('identityGet supertokensExternalId is required');
        }

        return ({
          url: '/identityGetV2',
          params: {
            supertokensExternalId,
          },
        });
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(mergeIdentities([data]));
      },
      providesTags: (result, error, arg) => [{ type: IDENTITY_TAG, id: getHash(arg) }],
    }),
    identitiesGet: builder.query({
      query: ({ identityIds }) => {
        if (!Array.isArray(identityIds)) {
          throw new Error('identitiesGet identityIds is required and must be an array');
        }

        return ({
          url: '/identitiesGetV2',
          params: {
            identityIds: `{${identityIds.join(',')}}`,
          },
        });
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(mergeIdentities(data));
      },
      providesTags: (result, error, arg) => [{ type: IDENTITY_TAG, id: getHash(arg) }],
    }),
    identitySubordinatesGet: builder.query({
      query: ({ identityId }) => {
        if (!identityId) {
          throw new Error('identitySubordinatesGet identityIds is required');
        }

        return ({
          url: '/identitySubordinatesGetV2',
          params: {
            identityId,
          },
        });
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(mergeIdentities(data));
      },
      providesTags: (result, error, arg) => [{ type: IDENTITY_TAG, id: getHash(arg) }],
    }),
  }),
});

export const {
  useGetIdentityQuery,
  useIdentitySubordinatesGetQuery,
  useLazyIdentitiesGetQuery,
  useLazyIdentityGetQuery,
  useLazyIdentitySubordinatesGetQuery,
  useGetOrganizationsQuery,
  useOrganizationUpsertMutation,
  useTeamUpsertMutation,
  useOrganizationPersonnelUpsertMutation,
  usePersonnelDisableLoginMutation,
} = identityApi;
