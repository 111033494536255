import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu } from '@mui/material';
import React, { useEffect, useState } from 'react';

import DashboardPermissionedControl from '@/components/DashboardPermissionedControl';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import { USER_ROLES } from '@/constants/roles';
import { useScrollBlock, useUserDashboardPermissions } from '@/hooks';
import tooltips from '@/utils/tooltips';

import MenuItemClone from './MenuItemClone';
import MenuItemDelete from './MenuItemDelete';
import MenuItemFavorite from './MenuItemFavorite';
import MenuItemOpenCreativesModal from './MenuItemOpenCreativesModal';
import MenuItemRename from './MenuItemRename';
import MenuItemShare from './MenuItemShare';

const MoreOptionsDashboard = ({
  dashboard,
  showFavorite = undefined,
  onConfirmDelete = undefined,
  onOpenCreativesModal = undefined,
  shouldShowCreatives = undefined,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [blockScroll, allowScroll] = useScrollBlock();

  const {
    hasSharePermission,
    hasRenamePermission,
    hasClonePermission,
    hasDeletePermission,
  } = useUserDashboardPermissions(dashboard.id);

  const handleOpen = (event) => {
    event.stopPropagation();
    blockScroll();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    allowScroll();
    setAnchorEl(null);
  };

  const handleConfirmDelete = () => {
    if (onConfirmDelete) {
      onConfirmDelete();
    }
    handleClose();
  };

  // Enable scrolling when the component is unmounted
  useEffect(() => {
    return () => {
      allowScroll();
    };
  }, [allowScroll]);

  return (
    <div>
      <TooltipInfo title={tooltips.dashboardCard.menu}>
        <IconButton aria-label="settings" onClick={handleOpen}>
          <MoreVertIcon color="secondary" />
        </IconButton>
      </TooltipInfo>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {!!showFavorite && (
          <MenuItemFavorite dashboard={dashboard} hasHorizontalPadding />
        )}
        <DashboardPermissionedControl
          dashboardId={dashboard.id}
          requiredPermissions={[USER_ROLES.EDITOR]}
        >
          <MenuItemShare dashboard={dashboard} disabled={!hasSharePermission} />
          <MenuItemRename dashboard={dashboard} disabled={!hasRenamePermission} onClose={handleClose} />
          <MenuItemClone dashboard={dashboard} disabled={!hasClonePermission} />
          <MenuItemDelete dashboard={dashboard} disabled={!hasDeletePermission} onDeleted={handleConfirmDelete} />
          {shouldShowCreatives ? <MenuItemOpenCreativesModal onClick={onOpenCreativesModal} /> : null}
        </DashboardPermissionedControl>
      </Menu>
    </div>
  );
};

export default MoreOptionsDashboard;
