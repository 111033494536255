/* eslint-disable no-secrets/no-secrets */
/* eslint-disable max-len */
import Passwordless from 'supertokens-auth-react/recipe/passwordless';
import { PasswordlessPreBuiltUI } from 'supertokens-auth-react/recipe/passwordless/prebuiltui';
import Session from 'supertokens-auth-react/recipe/session';

import env from './env-frontend-config';
import getWindowHandler from './lib/supertokens/getWindowHandler.mjs';

function getApiDomain() {
  return env.authMiddleTierUrlDomain;
}

function getWebsiteDomain() {
  return env.reactAppWebsiteUrlDomain;
}

export const SuperTokensConfig = {
  enableDebugLogs: false, // set to true to see debug logs in the browser console

  // see https://supertokens.com/docs/passwordless/common-customizations/translations
  // see https://github.com/supertokens/supertokens-auth-react/blob/master/lib/ts/recipe/passwordless/components/themes/translations.ts
  languageTranslations: {
    translations: {
      en: {
        PWLESS_SIGN_IN_UP_HEADER_TITLE: 'Sign In to Insights',
        PWLESS_USER_INPUT_CODE_HEADER_TITLE: 'Enter your One-time password',
        PWLESS_USER_INPUT_CODE_HEADER_SUBTITLE: 'A One-time password was sent to you at',
        PWLESS_RESEND_BTN_EMAIL: 'Resend One-time password',
        PWLESS_SIGN_IN_UP_CHANGE_CONTACT_INFO_EMAIL: 'Use a different email',
      },
    },
    defaultLanguage: 'en',
  },
  appInfo: {
    appName: 'Qloogle',
    apiDomain: getApiDomain(),
    websiteDomain: getWebsiteDomain(),
    apiBasePath: '/auth',
  },
  windowHandler: getWindowHandler,
  // recipeList contains all the modules that you want to
  // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
  recipeList: [
    Passwordless.init({
      useShadowDom: false,
      contactMethod: 'EMAIL',
      signInUpFeature: {
        resendEmailOrSMSGapInSeconds: 0.01,
      },
      // See https://supertokens.com/docs/passwordless/common-customizations/styling/changing-style#global-style-changes
      style: `
      body {
        background-image: linear-gradient(to right top, #D16BA5, #C777B9, #BA83CA, #AA8FD8, #9A9AE1, #8AA7EC, #79B3F4, #69BFF8, #52CFFE, #41DFFF, #46EEFA, #5FFBF1);
        background-size: 100vw 100vh;
      }
      .makeStyles-wrapper-1, .makeStyles-content-2, .jss1, .jss2 {
        background-color: transparent;
      }
      [data-supertokens~=container] {
        --palette-primary: 41, 98, 255;
        --palette-primaryBorder: 41, 98, 255;
      }
      [data-supertokens~=superTokensBranding] {
        display: none;
      }
      @keyframes cssAnimation {
        0%   { opacity: 0; }
        90%  { opacity: 0; }
        100% { opacity: 1; }
      }
      [data-supertokens~=resendCodeBtn] {
        animation: cssAnimation 8s forwards;
        opacity: 0; 
      }
      [data-supertokens~=container] {
        font-family: Roboto, Helvetica, Arial, sans-serif;
      }
      [data-supertokens~=row] {
        background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0nTGF5ZXJfMScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJyB2aWV3Qm94PScwIDAgMzIwIDE0MCc+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMyODMyM2M7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSdjbHMtMScgZD0nbTIxMi4xMyw2MC4yMmMtMS4zMyw0LjcxLTIuMTMsOS42NC0yLjEzLDE0Ljc4cy44LDEwLjA3LDIuMTMsMTQuNzhjNy4yNi0xLjA1LDEyLjg3LTcuMjQsMTIuODctMTQuNzhzLTUuNjEtMTMuNzQtMTIuODctMTQuNzhaJy8+PHBhdGggY2xhc3M9J2Nscy0xJyBkPSdtMTMyLjUsMTMwYy04LjI4LDAtMTUtNi43Mi0xNS0xNVYxNWMwLTguMjgsNi43Mi0xNSwxNS0xNXMxNSw2LjcyLDE1LDE1djEwMGMwLDguMjgtNi43MiwxNS0xNSwxNVonLz48cGF0aCBjbGFzcz0nY2xzLTEnIGQ9J20xMDguOTksMTE1LjM2bC04LjI1LTkuODNjNS44NC04Ljc0LDkuMjYtMTkuMjMsOS4yNi0zMC41MywwLTMwLjM4LTI0LjYyLTU1LTU1LTU1UzAsNDQuNjIsMCw3NXMyNC42Miw1NSw1NSw1NWM4LjE4LDAsMTUuOTEtMS44MywyMi44OS01LjA0bDguMTIsOS42OGMyLjk3LDMuNTQsNy4yMiw1LjM2LDExLjUsNS4zNiwzLjQsMCw2LjgyLTEuMTUsOS42NC0zLjUxLDYuMzUtNS4zMiw3LjE3LTE0Ljc5LDEuODUtMjEuMTNoLS4wMVpNMzAsNzVjMC0xMy43OSwxMS4yMS0yNSwyNS0yNXMyNSwxMS4yMSwyNSwyNS0xMS4yMSwyNS0yNSwyNS0yNS0xMS4yMS0yNS0yNVonLz48cGF0aCBjbGFzcz0nY2xzLTEnIGQ9J20yNjUsMjBjLTEwLjAzLDAtMTkuNCwyLjczLTI3LjUsNy40Mi04LjEtNC42OS0xNy40Ny03LjQyLTI3LjUtNy40Mi0zMC4zOCwwLTU1LDI0LjYyLTU1LDU1czI0LjYyLDU1LDU1LDU1YzEwLjAzLDAsMTkuNC0yLjczLDI3LjUtNy40Miw4LjEsNC42OSwxNy40Nyw3LjQyLDI3LjUsNy40MiwzMC4zOCwwLDU1LTI0LjYyLDU1LTU1cy0yNC42Mi01NS01NS01NVptLTU1LDgwYy0xMy43OSwwLTI1LTExLjIxLTI1LTI1czExLjIxLTI1LDI1LTI1LDI1LDExLjIxLDI1LDI1LTExLjIxLDI1LTI1LDI1Wm01NSwwYy0xLjk3LDAtMy44Ny0uMjktNS43MS0uNzIsMy42Mi03LjMzLDUuNzEtMTUuNTUsNS43MS0yNC4yOHMtMi4wOS0xNi45NS01LjcxLTI0LjI4YzEuODQtLjQzLDMuNzQtLjcyLDUuNzEtLjcyLDEzLjc5LDAsMjUsMTEuMjEsMjUsMjVzLTExLjIxLDI1LTI1LDI1WicvPjwvc3ZnPg==");
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: 2em;
        background-size: 10em;
        padding-top: 8em;
      }
      [data-supertokens~="inputWrapper"]:focus-within {
        border: 1px solid rgb(var(--palette-inputBorder));
        box-shadow: 0 0 0 0.2rem rgba(var(--palette-inputBorder), 0.25);
        outline: none;
      }
    `,
    }),
    Session.init({
      // see https://supertokens.com/docs/passwordless/common-customizations/sessions/token-transfer-method
      tokenTransferMethod: 'header', // or "cookie"

      /// https://supertokens.com/docs/passwordless/common-customizations/sessions/multiple-api-endpoints#step-1-backend-config
      /// https://supertokens.com/docs/passwordless/common-customizations/sessions/share-sessions-across-sub-domains
      /// since the backend and front end need to have this set up, we'll just
      /// hard code the domain here.
      sessionTokenFrontendDomain: '.qloo.com',
      sessionTokenBackendDomain: '.qloo.com',
      override: {
        functions: (oI) => ({
          ...oI,
          shouldDoInterceptionBasedOnUrl: (url, apiDomain, sessionTokenBackendDomain) => {
            const configuredApiDomain = getApiDomain();
            if (url.startsWith(configuredApiDomain)) {
              return true;
            }
            return oI.shouldDoInterceptionBasedOnUrl(url, apiDomain, sessionTokenBackendDomain);
          },
        }),
      },
    }),
  ],
};

export const PreBuiltUIList = [PasswordlessPreBuiltUI];

/*
  To generate the Qloo Background image:

  1) Use Original Qloo svg. To scale, you can use <svd transform="scale(1.00)"  id="Layer_1" ...>
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 140"><defs><style>.cls-1{fill:#28323c;}</style></defs><path class="cls-1" d="m212.13,60.22c-1.33,4.71-2.13,9.64-2.13,14.78s.8,10.07,2.13,14.78c7.26-1.05,12.87-7.24,12.87-14.78s-5.61-13.74-12.87-14.78Z"/><path class="cls-1" d="m132.5,130c-8.28,0-15-6.72-15-15V15c0-8.28,6.72-15,15-15s15,6.72,15,15v100c0,8.28-6.72,15-15,15Z"/><path class="cls-1" d="m108.99,115.36l-8.25-9.83c5.84-8.74,9.26-19.23,9.26-30.53,0-30.38-24.62-55-55-55S0,44.62,0,75s24.62,55,55,55c8.18,0,15.91-1.83,22.89-5.04l8.12,9.68c2.97,3.54,7.22,5.36,11.5,5.36,3.4,0,6.82-1.15,9.64-3.51,6.35-5.32,7.17-14.79,1.85-21.13h-.01ZM30,75c0-13.79,11.21-25,25-25s25,11.21,25,25-11.21,25-25,25-25-11.21-25-25Z"/><path class="cls-1" d="m265,20c-10.03,0-19.4,2.73-27.5,7.42-8.1-4.69-17.47-7.42-27.5-7.42-30.38,0-55,24.62-55,55s24.62,55,55,55c10.03,0,19.4-2.73,27.5-7.42,8.1,4.69,17.47,7.42,27.5,7.42,30.38,0,55-24.62,55-55s-24.62-55-55-55Zm-55,80c-13.79,0-25-11.21-25-25s11.21-25,25-25,25,11.21,25,25-11.21,25-25,25Zm55,0c-1.97,0-3.87-.29-5.71-.72,3.62-7.33,5.71-15.55,5.71-24.28s-2.09-16.95-5.71-24.28c1.84-.43,3.74-.72,5.71-.72,13.79,0,25,11.21,25,25s-11.21,25-25,25Z"/></svg>
    2) run it through https://svgencode.com/
  3 Choose the Base64 Encoded CSS output and paste above

*/
