import { createSlice } from '@reduxjs/toolkit';

export const identitiesSlice = createSlice({
  name: 'identities',
  initialState: {
    byId: {},
  },
  reducers: {
    fetchIdentityStart: (state, action) => {
      const { byId } = state;
      const identityId = action.payload;

      return {
        ...state,
        byId: {
          ...byId,
          [identityId]: {
            ...(byId[identityId] || {}),
          },
        },
      };
    },
    mergeIdentities: (state, action) => {
      const identities = action.payload;
      const nextById = { ...state.byId };
      identities.forEach((identity) => {
        nextById[identity.identityId] = { ...(state.byId[identity.identityId] || {}), ...identity };
      });

      return {
        ...state,
        byId: nextById,
      };
    },
  },
});

export const {
  fetchIdentityStart,
  mergeIdentities,
} = identitiesSlice.actions;
