import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useGetSearchQuery } from '@/store/apis/searchApi';
import { mergeEntities } from '@/store/slices/entitiesSlice';

import useDebounce from './useDebounce';

const useEntitiesSearch = ({
  search, types, take = 15, debounce = 250,
}) => {
  const dispatch = useDispatch();
  const debouncedSearch = useDebounce(search, debounce);

  const { data, isLoading, isFetching } = useGetSearchQuery({
    query: debouncedSearch,
    take,
    types,
  }, {
    skip: !debouncedSearch,
  });

  const isDebouncing = useMemo(() => debouncedSearch !== search, [debouncedSearch, search]);

  useEffect(() => {
    if (data?.results) {
      dispatch(mergeEntities(data.results));
    }
  }, [data, dispatch]);

  return {
    isLoading,
    isFetching,
    isDebouncing,
    results: data?.results,
  };
};

export default useEntitiesSearch;
