import { useState } from 'react';

import { api, paramNames } from '@/constants';
import serializeInsightsParams from '@/utils/serializeInsightsParams';

import { defaultPageOffset, defaultPageSize } from '../helpers/constants';

const useTagsFetcher = () => {
  const [state, setState] = useState({});

  const fetchByTagType = ({
    baseParams, panelParams, selectedCategory, tagType, highlightedSelectedCategory,
  }) => {
    setState((prevState) => ({
      ...prevState,
      [tagType]: {
        error: null,
        result: null,
        isLoading: true,
      },
    }));

    const {
      [paramNames.filterLocation]: filterLocation,
      ...restPanelParams
    } = panelParams;

    const tempParams = {
      ...restPanelParams,
      [paramNames.signalLocation]: filterLocation || restPanelParams[paramNames.signalLocation],
      [paramNames.filterTagTypes]: tagType,
      [paramNames.filterType]: 'urn:tag',
      [paramNames.filterParentTypes]: selectedCategory,
      offset: panelParams?.offset || defaultPageOffset,
      take: (panelParams?.take || defaultPageSize) + 1,
    };

    if (highlightedSelectedCategory) {
      tempParams[paramNames.filterTags] = { [highlightedSelectedCategory]: 'include' };
    }

    const serializedInsightsParams = serializeInsightsParams(baseParams, tempParams);

    const url = `${api.hostname}/api/${api.server}/v2/insights?${serializedInsightsParams}`;
    return fetch(url).then((response) => {
      if (response.ok) {
        return response.text();
      }

      return Promise.reject(new Error('Unable to fetch'));
    }).then((text) => {
      try {
        const json = JSON.parse(text);
        return json;
      } catch (e) {
        return Promise.reject(new Error('Unable to parse json'));
      }
    }).then((result) => {
      setState((prevState) => ({
        ...prevState,
        [tagType]: {
          isLoading: false,
          error: null,
          result: result?.results?.tags || [],
        },
      }));
    }, (error) => {
      setState((prevState) => ({
        ...prevState,
        [tagType]: {
          isLoading: false,
          error,
          result: null,
        },
      }));
    });
  };

  const fetchByTagTypes = ({
    baseParams,
    panelParams = {},
    selectedCategory,
    tagTypes,
    highlightedSelectedCategory,
  }) => Promise.all(tagTypes.map((tagType) => fetchByTagType({
    baseParams, panelParams, selectedCategory, tagType, highlightedSelectedCategory,
  })));

  return {
    fetchByTagTypes,
    state,
  };
};

export default useTagsFetcher;
