import AppBar from '@mui/material/AppBar';
import Slide from '@mui/material/Slide';
import { alpha, styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useScrolledFromTop } from '@v1/components/Header/AppBar';
import React, { useEffect } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ErrorBoundary from '@/components/ErrorBoundary';
import { useActiveDashboard } from '@/hooks';
import { getNewTabPositionForCreation, INITIAL_POSITION } from '@/screens/Dashboard/helpers/dashboardTabsUtils';
import { useGetDashboardQuery, useUpdateDashboardPositionMutation } from '@/store/apis/dashboardsApi';
import { addAndPersistDashboardTabId } from '@/store/slices/dashboardTabsSlice';

import DashboardActionsProvider from '../DashboardActionsProvider';
import DashboardBody from '../DashboardBody/DashboardBody';
import DashboardErrorFallback from '../DashboardErrorFallback/DashboardErrorFallback';
import DashboardHeader from '../DashboardHeader/DashboardHeader';
import DashboardVeltProvider from '../DashboardVeltProvider';
import DashboardViewSkeleton from './DashboardViewSkeleton';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  headerContainer: {
    transition: 'opacity 0.2s ease-in-out',
    opacity: 0.4,
  },
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
}));

const StyledAppBar = styled(AppBar)(({ theme }) => {
  const trigger = useScrolledFromTop({ threshold: 180 });

  return {
    position: 'fixed',
    top: 64,
    left: 0,
    right: 0,
    backgroundColor: alpha(theme.palette.background.paper, 1),
    boxShadow: 'rgba(0,0,0,0) 0px 2px 10px',
    transition: theme.transitions.create(['width', 'margin', 'box-shadow'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(trigger && {
      backdropFilter: 'blur(6px)',
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 10px',
      top: 0,
    }),
  };
});

const DashboardView = (props) => (
  <ErrorBoundary FallbackComponent={DashboardErrorFallback}>
    <DashboardContainer {...props} />
  </ErrorBoundary>
);

const DashboardContainer = ({ isLoading }) => {
  const dashboard = useActiveDashboard();
  const { id } = useParams();
  const { showBoundary } = useErrorBoundary();
  // Fetch dashboard if not in store
  const {
    isLoading: isDashboardLoading,
    error: dashboardError,
  } = useGetDashboardQuery(
    { id },
    { skip: isLoading || !id || (dashboard && dashboard.baseParams && dashboard.grantedPermissionTagIds) },
  );

  if (isDashboardLoading || isLoading) {
    return <DashboardViewSkeleton />;
  }

  if (dashboardError || !dashboard || !dashboard.baseParams) {
    showBoundary({
      dashboardId: id,
      data: dashboardError?.data,
      status: dashboardError?.status,
    });
    return null;
  }

  return (
    <DashboardVeltProvider dashboard={dashboard}>
      <DashboardActionsProvider dashboard={dashboard}>
        <Dashboard dashboard={dashboard} key={id} />
      </DashboardActionsProvider>
    </DashboardVeltProvider>
  );
};

const Dashboard = ({ dashboard }) => {
  const dispatch = useDispatch();
  const { dashboardIds } = useSelector((state) => state.dashboardTabs);

  const [updateDashboardPosition] = useUpdateDashboardPositionMutation();
  const { identity } = useSelector((state) => state.app);
  const { identityId } = identity;
  const isScrolledFromTop = useScrolledFromTop({ threshold: 180 });
  const classes = useStyles();

  useEffect(() => {
    dispatch(addAndPersistDashboardTabId(dashboard.id));
    const findDashboard = dashboardIds.find((d) => d === dashboard.id);
    if (!findDashboard) {
      const positions = dashboardIds.map(() => INITIAL_POSITION);
      const newPosition = dashboardIds.length === 0
        ? INITIAL_POSITION
        : getNewTabPositionForCreation(positions);
      updateDashboardPosition({
        dashboardId: dashboard.dashboardId,
        identityId,
        tabPosition: newPosition,
      });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard.id]);

  return (
    <div className={classes.container}>
      <Slide appear={false} direction="down" in={isScrolledFromTop}>
        <StyledAppBar>
          <DashboardHeader stickyView dashboard={dashboard} />
        </StyledAppBar>
      </Slide>

      <div className={`${classes.headerContainer} ${!isScrolledFromTop ? classes.visible : classes.hidden}`}>
        <DashboardHeader dashboard={dashboard} />
      </div>
      <DashboardBody dashboard={dashboard} />
    </div>
  );
};

export default DashboardView;
