import { Pagination, PaginationItem } from '@mui/material';
import React, { useMemo } from 'react';

import { useDashboardActionsContext } from '../DashboardActionsProvider';
import { defaultPageOffset, defaultPageSize } from './helpers/constants';

const TablePagination = ({
  panelId, panelParams, hasMorePages,
}) => {
  const dashboardActions = useDashboardActionsContext();

  const offset = useMemo(() => panelParams.offset || defaultPageOffset, [panelParams]);
  const take = useMemo(() => panelParams.take || defaultPageSize, [panelParams]);
  const page = useMemo(() => Math.round(offset / take) + 1, [offset, take]);

  const handlePageChange = ((e, newPage) => {
    const nextOffset = (newPage - 1) * take;

    dashboardActions.changePanelParams({
      panelId,
      nextParams: {
        ...panelParams,
        offset: nextOffset,
      },
    });
  });

  return (
    <Pagination
      count={hasMorePages ? page + 2 : page}
      page={page}
      size="small"
      onChange={handlePageChange}
      siblingCount={0}
      boundaryCount={1}
      color="primary"
      renderItem={(item) => {
        // Add ellipsis item before the last page
        if (hasMorePages && item.page === page + 2) {
          return <PaginationItem {...item} disabled type="end-ellipsis" />;
        }
        return <PaginationItem {...item} />;
      }}
    />
  );
};

export default TablePagination;
