/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { EditOutlined } from '@mui/icons-material';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useLiveState } from '@veltdev/react';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import React, {
  useCallback,
  useEffect, useMemo,
  useState,
} from 'react';
import {
  BsTypeBold,
  BsTypeH1, BsTypeH2, BsTypeH3, BsTypeItalic,
  BsTypeStrikethrough,
} from 'react-icons/bs';
import { GoHorizontalRule, GoListOrdered, GoListUnordered } from 'react-icons/go';

import LoadingForScreenshot from '@/components/LoadingForScreenshot';
import { useUserDashboardPermissions } from '@/hooks';

import { useDashboardActionsContext } from '../DashboardActionsProvider';
import styles from './PanelBodyFreeformText.module.scss';
import PanelBodyFreeformTextEditorButton from './PanelBodyFreeformTextEditorButton';

const PanelBodyFreeformText = ({
  dashboardId,
  panelId,
  panelSettings,
}) => {
  const dashboardActions = useDashboardActionsContext();
  const { canEdit } = useUserDashboardPermissions(dashboardId);

  const [content, setContent] = useLiveState(`freetext-${dashboardId}-${panelId}`, panelSettings.content);

  const [isLoading, setIsLoading] = useState(true);

  const [isFocused, setIsFocused] = useState(false);
  const onUpdate = useCallback((event) => {
    if (!canEdit) {
      return;
    }

    const json = event.editor.getJSON();

    const nextSettings = {
      ...panelSettings,
      content: json,
    };
    setContent(json);
    dashboardActions.changePanelSettings({ panelId, nextSettings });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnUpdate = useMemo(() => debounce(onUpdate, 300), []);

  const editor = useEditor({
    extensions: [StarterKit],
    content,
    editable: canEdit,
    onUpdate: debouncedOnUpdate,
    onFocus: () => setIsFocused(true),
    onBlur: () => setIsFocused(false),
    onCreate: () => setIsLoading(false),
  });

  useEffect(() => {
    const currentContent = editor.getJSON();
    const newContent = content;

    if (!isEqual(currentContent, newContent)) {
      editor.commands.setContent(content);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const buttonMenuClassNames = [styles.buttonMenu];
  if (!isFocused) {
    buttonMenuClassNames.push(styles.hidden);
  }

  return (
    <div className={styles.container}>
      <LoadingForScreenshot isLoading={isLoading} />
      {canEdit && (
        <div className={buttonMenuClassNames.join(' ')}>
          <PanelBodyFreeformTextEditorButton
            onClick={() => editor.chain().focus().toggleBold().run()}
            active={editor.isActive('bold')}
            Icon={BsTypeBold}
          />

          <PanelBodyFreeformTextEditorButton
            onClick={() => editor.chain().focus().toggleItalic().run()}
            active={editor.isActive('italic')}
            Icon={BsTypeItalic}
          />

          <PanelBodyFreeformTextEditorButton
            onClick={() => editor.chain().focus().toggleStrike().run()}
            active={editor.isActive('strike')}
            Icon={BsTypeStrikethrough}
          />

          <PanelBodyFreeformTextEditorButton
            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
            active={editor.isActive('heading', { level: 1 })}
            Icon={BsTypeH1}
          />

          <PanelBodyFreeformTextEditorButton
            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
            active={editor.isActive('heading', { level: 2 })}
            Icon={BsTypeH2}
          />

          <PanelBodyFreeformTextEditorButton
            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
            active={editor.isActive('heading', { level: 3 })}
            Icon={BsTypeH3}
          />

          <PanelBodyFreeformTextEditorButton
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            active={editor.isActive('bulletList')}
            Icon={GoListUnordered}
          />

          <PanelBodyFreeformTextEditorButton
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            active={editor.isActive('orderedList')}
            Icon={GoListOrdered}
          />

          <PanelBodyFreeformTextEditorButton
            onClick={() => editor.chain().focus().setHorizontalRule().run()}
            active={editor.isActive('horizontalRule')}
            Icon={GoHorizontalRule}
          />
        </div>
      )}
      <div className={styles.body}>
        <EditorContent editor={editor} />
        {!editor.getText() && !isFocused && (
          <div className={styles.emptyOverlayOuterContainer}>
            <div className={styles.emptyOverlayInnerContainer}>
              <div className={styles.center}>
                <div
                  className={styles.centered}
                  onClick={() => editor.commands.focus('start')}
                >
                  <div className={`${styles.emptyOverlayIcon } ${ styles.center}`}>
                    <EditOutlined fontSize="large" />
                  </div>
                  <div className={styles.emptyOverlayText}>Click here to start typing</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PanelBodyFreeformText;
