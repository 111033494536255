import clx from 'clsx';
import React from 'react';

import styles from './DashboardPanel.module.scss';

const PanelBodyError = ({ error = undefined, message, className }) => (
  <div className={clx(className, styles.panelErrorContainer)}>
    {error || message ? (
      <pre className={styles.panelErrorMessage}>{error ?? message}</pre>
    ) : (
      <div className={styles.panelErrorText}>
        This query returned no results.
      </div>
    )}
  </div>
);

export default PanelBodyError;
