import React, { useState } from 'react';

import DashboardPermissionedControl from '@/components/DashboardPermissionedControl';
import LocationPopover from '@/components/LocationPopover';
import { USER_ROLES } from '@/constants/roles';
import { useActiveDashboard, useDescribeLocation } from '@/hooks';

import LocationParam from '../LocationParam/LocationParam';

const PanelParamLocation = ({
  location, onChange, onReset,
}) => {
  const dashboard = useActiveDashboard();
  const describedLocation = useDescribeLocation(location);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <DashboardPermissionedControl
        dashboardId={dashboard.id}
        requiredPermissions={[USER_ROLES.EDITOR]}
      >
        <LocationParam
          color="blue"
          location={describedLocation}
          onClick={handleClick}
          placeholder="Select location"
        />
      </DashboardPermissionedControl>
      <LocationPopover
        location={describedLocation}
        anchorEl={anchorEl}
        onClose={handleClose}
        onChange={onChange}
        onReset={onReset}
      />
    </div>
  );
};

export default PanelParamLocation;
